<template>
  <div class="modal --primary" :class="{ '--show': isShow }">
    <div class="modal__bg" @click.stop="closeModal"></div>
    <div class="modal__body">
      <button class="modal__close--circle" @click.stop="closeModal"></button>
      <div class="modal__subtitle">【東京競馬場 混雑状況表示サイト】</div>
      <div class="modal__title">本日は<br />サービスを行っておりません</div>
      <p class="modal__desc">※東京競馬開催日のみ表示いたします。</p>
      <div class="modal__footer">
        <button class="modal__close" @click.stop="closeModal">閉じる</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalOutOfTerm",
  props: ["isShow"],
  methods: {
    closeModal() {
      this.$emit("from-modal");
    },
  },
};
</script>
