<template>
  <div class="content__data pb20" :class="className">
    <div class="content__map --2">
      <img
        v-if="dataList"
        v-lazy="require(`@/assets/img/sp/${dataList.map.image.src}.png`)"
        :srcset="`${require(`@/assets/img/sp/${dataList.map.image.src}@2x.png`)} 2x`"
        :width="dataList.map.image.width"
        :height="dataList.map.image.height"
        :alt="dataList.map.image.alt"
        class="map__image--map --2"
      />
      <ul v-if="statusList" class="bubble__list">
        <li
          v-for="(item, index) in statusList.data"
          class="bubble__item"
          :class="item.class"
          :key="index"
        >
          <img
            v-if="item.info.img"
            v-lazy="
              require(`@/assets/img/sp/status/${item.category}/${item.info.img}.png`)
            "
            :srcset="`${require(`@/assets/img/sp/status/${item.category}/${item.info.img}@2x.png`)} 2x`"
            :alt="item.info.alt"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToiletList",
  props: {
    className: String,
    dataList: {
      type: Object,
      default: null,
    },
    statusList: Object,
  },
};
</script>
