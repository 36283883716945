<template>
  <div class="signage signage-3">
    <div class="img_container">
      <img
        v-if="currentId === '2'"
        src="@/assets/img/signage/img-floor-2f.png"
        width="1080"
        height="1920"
        alt="フロアマップ2F"
      />
      <img
        v-else-if="currentId === '3'"
        src="@/assets/img/signage/img-floor-3f.png"
        width="1080"
        height="1920"
        alt="フロアマップ3F"
      />
      <div v-if="currentId === '2'" class="signage-floormap">
        <signage-data-list :statusList="floorData2" />
      </div>
      <div v-if="currentId === '3'" class="signage-floormap">
        <signage-data-list :statusList="floorData3" />
      </div>
      <div v-if="currentId === '2'" class="signage-floormap">
        <signage-camera-list :dataList="cameraDataList" />
      </div>
    </div>
    <div v-if="tmp" class="content__text-container--tmp is-signage">
      <p class="content__text--tmp is-red">
        現在、アクセスが集中し、<br />ページを閲覧しづらい状態になっております。
      </p>
      <p class="content__text--tmp">
        日頃より、中山競馬場をご利用いただきましてありがとうございます。
      </p>
      <p class="content__text--tmp">
        現在、場内の電波状況により、ページ閲覧をしづらい状態になっております。
      </p>
      <p class="content__text--tmp">
        ご迷惑をおかけしまして誠に申し訳ございません。
      </p>
    </div>
  </div>
</template>
<script>
import SignageDataList from "../../components/list/SignageDataList.vue";
import SignageCameraList from "../../components/list/SignageCameraList.vue";

export default {
  components: {
    SignageDataList,
    SignageCameraList,
  },
  data() {
    return {
      testId: null,
      tmp: false,
      currentId: null,
      jsonList: [],
      floorData: {},
      floorData2: {
        data: [
          {
            category: "floormap",
            img: "status-1",
            name: "2f-1",
            width: "188",
            height: "28",
            alt: "66",
            class: "frame_center",
          },
          {
            category: "floormap",
            img: "status-1",
            name: "2f-2",
            width: "188",
            height: "28",
            alt: "65",
            class: "frame_center",
          },
          {
            category: "floormap",
            img: "status-1",
            name: "2f-3",
            width: "188",
            height: "28",
            alt: "64",
            class: "frame_center",
          },
          {
            category: "floormap",
            img: "status-1",
            name: "2f-4",
            width: "188",
            height: "28",
            alt: "40",
            class: "frame_center",
          },
          {
            category: "floormap",
            img: "status-1",
            name: "2f-5",
            width: "188",
            height: "28",
            alt: "41",
            class: "frame_center",
          },
          {
            category: "floormap",
            img: "status-1",
            name: "2f-6",
            width: "188",
            height: "28",
            alt: "38",
            class: "frame_center",
          },
          {
            category: "floormap",
            img: "status-1",
            name: "2f-7",
            width: "188",
            height: "28",
            alt: "39",
            class: "frame_center",
          },
          {
            category: "floormap",
            img: "status-1",
            name: "2f-8",
            width: "188",
            height: "28",
            alt: "42",
            class: "frame_center",
          },
          {
            category: "floormap",
            img: "status-1",
            name: "2f-9",
            width: "188",
            height: "28",
            alt: "43",
            class: "frame_center",
          },
          {
            category: "floormap",
            img: "status-1",
            name: "2f-10",
            width: "188",
            height: "28",
            alt: "44",
            class: "frame_center",
          },
          {
            category: "floormap",
            img: "status-1",
            name: "2f-11",
            width: "188",
            height: "28",
            alt: "45",
            class: "frame_center",
          },
          {
            category: "floormap",
            img: "status-1",
            name: "2f-12",
            width: "188",
            height: "28",
            alt: "46",
            class: "frame_center",
          },
          {
            category: "floormap",
            img: "status-1",
            name: "2f-13",
            width: "188",
            height: "28",
            alt: "47",
            class: "frame_center",
          },
        ],
      },
      floorData3: {
        data: [
          {
            category: "floormap",
            img: "status-1",
            name: "3f-1",
            width: "188",
            height: "28",
            alt: "62",
            class: "frame_center",
          },
          {
            category: "floormap",
            img: "status-1",
            name: "3f-2",
            width: "188",
            height: "28",
            alt: "61",
            class: "frame_center",
          },
          {
            category: "floormap",
            img: "status-1",
            name: "3f-3",
            width: "188",
            height: "28",
            alt: "21",
            class: "frame_center",
          },
          {
            category: "floormap",
            img: "status-1",
            name: "3f-4",
            width: "188",
            height: "28",
            alt: "22",
            class: "frame_center",
          },
          {
            category: "floormap",
            img: "status-1",
            name: "3f-5",
            width: "188",
            height: "28",
            alt: "23",
            class: "frame_center",
          },
          {
            category: "floormap",
            img: "status-1",
            name: "3f-6",
            width: "188",
            height: "28",
            alt: "24",
            class: "frame_center",
          },
          {
            category: "floormap",
            img: "status-1",
            name: "3f-7",
            width: "188",
            height: "28",
            alt: "25",
            class: "frame_center",
          },
          {
            category: "floormap",
            img: "status-1",
            name: "3f-8",
            width: "188",
            height: "28",
            alt: "27",
            class: "frame_center",
          },
          {
            category: "floormap",
            img: "status-1",
            name: "3f-9",
            width: "188",
            height: "28",
            alt: "26",
            class: "frame_center",
          },
          {
            category: "floormap",
            img: "status-1",
            name: "3f-10",
            width: "188",
            height: "28",
            alt: "28",
            class: "frame_center",
          },
        ],
      },
      cameraDataList: [
        {
          width: "510",
          height: "289",
          class: "fastfood",
          info: {
            alt: "48_ファストフードウエスト_F2F",
            camera_no: "48",
            img: "",
          },
        },
      ],
      env: process.env.VUE_APP_NODE_ENV,
      envBase: process.env.VUE_APP_2024_2nd_S3_BASE_URL,
      envBaseTmp: process.env.VUE_APP_TMP_S3_BASE_URL,
    };
  },
  created() {
    this.testId = this.$router.currentRoute.params.test;
    this.getData(this.testId);
    this.getCameraData(this.cameraDataList);
    this.checkMessage();
  },
  mounted() {
    this.getPosition();
    this.timeout = setInterval(() => {
      this.getData(this.testId);
      this.getCameraData(this.cameraDataList);
      this.checkMessage();
    }, 20000);
  },
  beforeDestroy() {
    clearInterval(this.timeout);
  },
  methods: {
    getPosition() {
      this.currentId = this.$router.currentRoute.params.id;
      const currentList = ["2", "3"];
      if (!currentList.includes(this.currentId)) {
        this.currentId = "2";
      }
    },
    async checkMessage() {
      await this.$axios
        .get(`${this.envBaseTmp}message.json?` + new Date().getTime())
        .then((response) => {
          this.tmp = response.data.message;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getData(id) {
      const jsonURL =
        id === "99"
          ? `https://stg.jra-keibajo-konzatsu.jp/status_axis_1.json?`
          : `${this.envBase}${this.env}/congestion/axis_1.json?`;
      await this.$axios
        .get(jsonURL + new Date().getTime())
        .then((response) => {
          this.jsonList = response.data;
          this.searchData(this.jsonList);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getCameraData(list) {
      await this.$axios
        .get(
          `${this.envBase}${this.env}/camera_images.json?` +
            new Date().getTime()
        )
        .then((response) => {
          this.setCameraImg(response.data, list);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchData(jsonData) {
      if (this.currentId === "3") {
        this.floorData = this.floorData3;
      } else {
        this.floorData = this.floorData2;
      }
      for (let item of Object.entries(this.floorData || {})) {
        const listData = item[1];
        for (const i of listData) {
          this.divideData(i, jsonData);
        }
      }
    },
    divideData(i, jsonData) {
      let index = jsonData.find((element) => element.camera_no === i.alt);
      if (index !== undefined) {
        const status = index.status;
        if (status === 2) {
          i.img = "status-5";
        } else if (status === 3) {
          i.img = "status-6";
        } else {
          this.setImg(index, i);
        }
      }
    },
    setImg(index, i) {
      const level = index.congestion_level;
      if (level) {
        i.img = `status-${level}`;
      }
    },
    setCameraImg(jsonData, listData) {
      for (const i in listData) {
        const target = listData[i].info;
        const index = jsonData.find(
          (element) => element.camera_no === target.camera_no
        );
        target.img = index.image_url + "?" + new Date().getTime();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
