<template>
  <div class="camera" :class="dataList.type">
    <div v-if="dataList.type === 'type1'" class="camera__title">
      <img
        v-lazy="
          require(`@/assets/img/sp/${dataList.title.name}.${dataList.title.extension}`)
        "
        :srcset="`${require(`@/assets/img/sp/${dataList.title.name}@2x.${dataList.title.extension}`)} 2x`"
        :width="dataList.title.width"
        :height="dataList.title.height"
        :alt="dataList.title.alt"
      />
    </div>
    <img
      v-if="dataList.type === 'type1'"
      v-lazy="dataList.info.img"
      :width="dataList.width"
      :height="dataList.height"
      :alt="dataList.info.alt"
      class="camera__img"
    />
    <div v-else-if="dataList.type === 'type3'" class="camera__text">
      本日馬場内グルメエリアで<br />開催しているイベントはございません。
    </div>
    <img
      v-else
      v-lazy="dataList.info.img"
      :alt="dataList.info.alt"
      class="camera__img"
      :width="dataList.width"
      :height="dataList.height"
    />
  </div>
</template>

<script>
export default {
  name: "LiveCamera",
  props: ["dataList"],
};
</script>
