import { render, staticRenderFns } from "./Signage2.vue?vue&type=template&id=1c3d74c2&scoped=true"
import script from "./Signage2.vue?vue&type=script&lang=js"
export * from "./Signage2.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c3d74c2",
  null
  
)

export default component.exports