<template>
  <div class="signage signage-1">
    <div class="img_container">
      <img
        key="food"
        src="@/assets/img/signage/img-food.png"
        width="1080"
        height="1920"
        class="slideshow fadeout"
        alt="フードエリア"
      />
      <img
        key="shop"
        :src="require(`@/assets/img/signage/img-shop-food-${currentFloor}.png`)"
        width="1080"
        height="1920"
        class="slideshow"
        alt="飲食店／フードコート"
      />
      <transition name="fade">
        <div v-if="index === 0" class="signage-food">
          <signage-camera-list :dataList="cameraData" />
        </div>
      </transition>
      <transition name="fade">
        <div v-if="index === 1" class="signage-shop-food">
          <signage-data-list :statusList="floorData" />
        </div>
      </transition>
      <transition name="fade">
        <div v-if="currentId" class="qr-pos">
          <img
            :src="require(`@/assets/img/signage/qr/QR_${currentQr}.png`)"
            width="195"
            height="195"
            class="signage-qr"
            alt=""
          />
        </div>
      </transition>
    </div>
    <transition name="fade">
      <div v-if="tmp" class="content__text-container--tmp is-signage">
        <p class="content__text--tmp is-red">
          現在、アクセスが集中し、<br />ページを閲覧しづらい状態になっております。
        </p>
        <p class="content__text--tmp">
          日頃より、東京競馬場をご利用いただきましてありがとうございます。
        </p>
        <p class="content__text--tmp">
          現在、場内の電波状況により、ページ閲覧をしづらい状態になっております。
        </p>
        <p class="content__text--tmp">
          ご迷惑をおかけしまして誠に申し訳ございません。
        </p>
      </div>
    </transition>
  </div>
</template>
<script>
import SignageDataList from "../../components/list/SignageDataList.vue";
import SignageCameraList from "../../components/list/SignageCameraList.vue";

export default {
  components: {
    SignageDataList,
    SignageCameraList,
  },
  data() {
    return {
      testId: null,
      tmp: false,
      index: 0,
      intervalId: undefined,
      currentId: null,
      currentQr: null,
      currentFloor: 0,
      // sleep_ptn: [1, 60, 9],
      sleep_ptn: [30, 15],
      sleep_ptn_index: 0,
      jsonList: [],
      cameraData: [
        {
          name: "fastfoodwest-2",
          extension: "jpg",
          width: "510",
          height: "288",
          info: {
            alt: "48_ファストフードウエスト_F2F",
            camera_no: "48",
            img: "",
            src: "img-ffp-west",
          },
        },
        {
          name: "fastfoodeast-1",
          extension: "jpg",
          width: "510",
          height: "288",
          info: {
            alt: "56_ファストフードイースト_F1F",
            camera_no: "56",
            img: "",
            src: "img-ffp-west",
          },
        },
        {
          name: "foodcourt-1",
          extension: "jpg",
          width: "510",
          height: "288",
          info: {
            alt: "68_フードコート_MB1F_南",
            camera_no: "68",
            img: "",
            src: "img-ffp-2",
          },
        },
        {
          name: "foodcourt-2",
          extension: "jpg",
          width: "510",
          height: "288",
          info: {
            alt: "69_フードコート_MB1F_北",
            camera_no: "69",
            img: "",
            src: "img-ffp-1",
          },
        },
        {
          name: "bajo",
          extension: "jpg",
          width: "510",
          height: "288",
          info: {
            alt: "57_馬場内",
            camera_no: "57",
            img: "",
            src: "img-bajo",
          },
        },
      ],
      floorData: {
        data: [
          {
            category: "food",
            alt: "1",
            name: "twodays_6f_1",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "2",
            name: "tokiwa",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "3",
            name: "twodays_6f_2",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "58",
            name: "okura",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            hash: "iso",
            hasForecast: true,
            category: "food",
            alt: "10",
            name: "iso",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "9",
            name: "goodsmile",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            hash: "kandagawa",
            hasForecast: true,
            category: "food",
            alt: "8",
            name: "kandagawa",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            hash: "musashi",
            hasForecast: true,
            category: "food",
            alt: "7",
            name: "musashi",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            hash: "torikoma",
            hasForecast: true,
            category: "food",
            alt: "6",
            name: "torikoma",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            hash: "belmont",
            hasForecast: true,
            category: "food",
            alt: "5",
            name: "belmont",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "4",
            name: "twodays_5f",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "19",
            name: "sanko",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "20",
            name: "sanko_2",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "18",
            name: "gindako",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "17",
            name: "jindaiji",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "16",
            name: "twodays_4f",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "61",
            name: "sapporo",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "62",
            name: "newtokyo",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            hash: "excelsior",
            hasForecast: true,
            category: "food",
            alt: "28",
            name: "excelsior",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            hash: "seven",
            hasForecast: true,
            category: "food",
            alt: "26",
            name: "seven",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "27",
            name: "cavallo",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "25",
            name: "pizzala",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "24",
            name: "firstkitchen",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "22",
            name: "pepperlunch",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "21",
            name: "tokyocafebakery",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "23",
            name: "2400tokyo",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "66",
            name: "twodays_2f",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "65",
            name: "tomatsu",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "64",
            name: "yanagi",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "47",
            name: "umeya",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "44",
            name: "fukumitsu_1",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "45",
            name: "horseai",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "46",
            name: "fukumitsu_2",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "43",
            name: "kokoro",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "42",
            name: "baji",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "39",
            name: "fukumitsu",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "38",
            name: "koichiro",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "41",
            name: "tonya_2f",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "40",
            name: "kasuya",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "55",
            name: "tonya_1f",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "52",
            name: "twodays_1f",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "54",
            name: "joy",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "53",
            name: "moriyama",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
          {
            category: "food",
            alt: "51",
            name: "inamatsu",
            width: "183",
            height: "28",
            class: "frame_center",
            img: "status-1",
          },
        ],
      },
      env: process.env.VUE_APP_NODE_ENV,
      envBase: process.env.VUE_APP_2024_2nd_S3_BASE_URL,
      envBaseTmp: process.env.VUE_APP_TMP_S3_BASE_URL,
    };
  },
  created() {
    this.testId = this.$router.currentRoute.params.test;
    this.getData(this.testId);
    this.getCameraData(this.cameraData);
    this.checkMessage();
  },
  mounted() {
    this.getPosition();
    const images = document.getElementsByClassName("slideshow");
    this.slideshow(images);
    this.updateData();
    this.timeout = setInterval(() => {
      this.getData(this.testId);
      this.getCameraData(this.cameraData);
      this.checkMessage();
    }, 20000);
    // }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.timeout);
  },
  methods: {
    slideshow(images) {
      const current = images[this.index];
      const prev = images[this.index - 1]
        ? images[this.index - 1]
        : images[images.length - 1];
      current.classList.add("fadein");
      current.classList.remove("fadeout");
      prev.classList.remove("fadein");
      prev.classList.add("fadeout");
    },
    slideInterval() {
      const images = document.getElementsByClassName("slideshow");
      this.index = this.index < images.length - 1 ? this.index + 1 : 0;
      this.slideshow(images);
    },
    getPosition() {
      this.currentId = this.$router.currentRoute.params.id;
      switch (this.currentId) {
        case "1":
          this.currentQr = "fuji5F";
          this.currentFloor = 5;
          break;
        case "2":
          this.currentQr = "fuji3F";
          this.currentFloor = 3;
          break;
        case "3":
          this.currentQr = "fuji2F_west";
          this.currentFloor = 2;
          break;
        case "4":
          this.currentQr = "fuji2F_info";
          this.currentFloor = 2;
          break;
        case "5":
          this.currentQr = "fuji1F";
          this.currentFloor = 1;
          break;
        case "6":
          this.currentQr = "memorial2F";
          this.currentFloor = 2;
          break;
        default:
          this.currentQr = null;
          break;
      }
      const currentList = ["1", "2", "3", "4", "5", "6"];
      if (!currentList.includes(this.currentId)) {
        this.currentId = null;
      }
    },
    async checkMessage() {
      await this.$axios
        .get(`${this.envBaseTmp}message.json?` + new Date().getTime())
        .then((response) => {
          this.tmp = response.data.message;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getData(id) {
      const jsonURL =
        id === "99"
          ? `https://stg.jra-keibajo-konzatsu.jp/status_axis_1.json?`
          : `${this.envBase}${this.env}/congestion/axis_1.json?`;
      await this.$axios
        .get(jsonURL + new Date().getTime())
        .then((response) => {
          this.jsonList = response.data;
          this.searchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getCameraData(list) {
      await this.$axios
        .get(
          `${this.envBase}${this.env}/camera_images.json?` +
            new Date().getTime()
        )
        .then((response) => {
          this.setCameraImg(response.data, list);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchData() {
      for (let item of Object.entries(this.floorData || {})) {
        const listData = item[1];
        for (const i of listData) {
          this.divideData(i, this.jsonList);
        }
      }
    },
    divideData(i, jsonData) {
      let index = jsonData.find((element) => element.camera_no === i.alt);
      if (index !== undefined) {
        // console.log(
        //   `${index.name}と${index.status}と${index.congestion_level}`
        // );
        const status = index.status;
        if (status === 2) {
          i.img = "status-5";
        } else if (status === 3) {
          i.img = "status-6";
        } else {
          this.setImg(index.congestion_level, i);
        }
      }
    },
    setImg(level, i) {
      if (level) {
        i.img = `status-${level}`;
        // console.log(i.img);
      }
    },
    setCameraImg(jsonData, listData) {
      for (const i of listData) {
        // console.log(i);
        const target = i.info;
        const index = jsonData.find(
          (element) => element.camera_no === target.camera_no
        );
        // console.log(index);
        if (index !== undefined) {
          target.img = index.image_url + "?" + new Date().getTime();
          // console.log(target.img);
        }
      }
    },
    updateData() {
      this.slideInterval();
      this.getCameraData(this.cameraData);
      // console.log(
      //   this.sleep_ptn[this.sleep_ptn_index] +
      //     "s" +
      //     "some_func() " +
      //     new Date() +
      //     " next: " +
      //     this.sleep_ptn[this.sleep_ptn_index] +
      //     "s"
      // );
      this.timeout = setTimeout(
        this.updateData,
        this.sleep_ptn[this.sleep_ptn_index] * 1000
      );
      this.sleep_ptn_index = (this.sleep_ptn_index + 1) % this.sleep_ptn.length;
      // clearTimeout(timer);
    },
  },
};
</script>

<style lang="scss" scoped></style>
