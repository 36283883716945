import Vue from "vue";
import VueRouter from "vue-router";
import Index from "@/pages/Index";
import Signage1 from "@/pages/signage/Signage1";
import Signage2 from "@/pages/signage/Signage2";
import Signage3 from "@/pages/signage/Signage3";
import Admin from "@/pages/Admin";
import AdminSetting from "@/pages/AdminSetting";
import ChangeStatus from "@/pages/admin/ChangeStatus";
import ChangeStatusParking from "@/pages/admin/ChangeStatusParking";
// import Forecast from "@/pages/Forecast";
import SpToilet from "@/pages/sp/SpToilet";
import SpParking from "@/pages/sp/SpParking";
// import Text from "@/pages/Text";

Vue.use(VueRouter);

const publicPath = process.env.VUE_APP_PUBLIC_PATH;

/**
 *
 */
const routes = [
  {
    path: publicPath + "",
    redirect: publicPath + "tokyo/shops/",
    // name: "Text",
    // component: Text,
    // meta: {
    //   layout: "detail"
    // }
    name: "index",
    component: Index,
  },
  // 管理画面_営業時間設定画面
  {
    path: publicPath + "tokyo/admin/setting/",
    name: "AdminSetting",
    component: AdminSetting,
    meta: {
      layout: "admin",
    },
  },
  // 管理画面_閾値設定画面
  {
    path: publicPath + "tokyo/admin",
    name: "Admin",
    component: Admin,
    meta: {
      layout: "admin",
    },
  },
  // {
  //   path: publicPath + "tokyo/forecast/",
  //   name: "Forecast",
  //   component: Forecast,
  // },
  // 管理画面_駐車場手動混雑設定画面
  {
    path: publicPath + "tokyo/i/parking/",
    name: "ChangeStatusParking",
    component: ChangeStatusParking,
    meta: {
      layout: "admin",
    },
  },
  // 管理画面_手動混雑設定画面（Umajo/ターフィー）
  {
    path: publicPath + "tokyo/i/:page",
    name: "ChangeStatus",
    component: ChangeStatus,
    meta: {
      layout: "admin",
    },
  },
  {
    path: publicPath + "tokyo/toilet/",
    name: "SpToilet",
    component: SpToilet,
  },
  // jsonテスト用
  {
    path: publicPath + "tokyo/toilet/:test",
    name: "SpToiletTest",
    component: SpToilet,
  },
  {
    path: publicPath + "tokyo/parking/",
    name: "SpParking",
    component: SpParking,
  },
  {
    path: publicPath + "tokyo/:page/",
    name: "index-tab",
    component: Index,
  },
  // jsonテスト用
  {
    path: publicPath + "tokyo/:page/:test",
    name: "index-tab-test",
    component: Index,
  },
  // 2画面切り替え 飲食店等
  {
    path: publicPath + "tokyo/s/shops/",
    name: "Signage1",
    component: Signage1,
    meta: {
      layout: "detail",
    },
  },
  {
    path: publicPath + "tokyo/s/shops/:id",
    name: "Signage1Current",
    component: Signage1,
    meta: {
      layout: "detail",
    },
  },
  // jsonテスト用
  {
    path: publicPath + "tokyo/s/shops/:id/:test",
    name: "Signage1CurrentTest",
    component: Signage1,
    meta: {
      layout: "detail",
    },
  },
  // 3画面切り替え 飲食店以外
  {
    path: publicPath + "tokyo/s/ticket-paddock/",
    name: "Signage2",
    component: Signage2,
    meta: {
      layout: "detail",
    },
  },
  {
    path: publicPath + "tokyo/s/ticket-paddock/:id",
    name: "Signage2Current",
    component: Signage2,
    meta: {
      layout: "detail",
    },
  },
  // jsonテスト用
  {
    path: publicPath + "tokyo/s/ticket-paddock/:id/:test",
    name: "Signage2CurrentTest",
    component: Signage2,
    meta: {
      layout: "detail",
    },
  },
  {
    path: publicPath + "tokyo/s/floor/:id",
    name: "Signage3",
    component: Signage3,
    meta: {
      layout: "detail",
    },
  },
  // jsonテスト用
  {
    path: publicPath + "tokyo/s/floor/:id/:test",
    name: "Signage3Test",
    component: Signage3,
    meta: {
      layout: "detail",
    },
  },
  // {
  //   path: publicPath + "tokyo/s/paddock/",
  //   name: "Signage3",
  //   component: Signage3,
  //   meta: {
  //     layout: "detail",
  //   },
  // },
  {
    path: "*",
    redirect: publicPath + "tokyo/shops/",
  },
];

// routesを生成する処理
function generateRoutes() {
  let processed_routes = [];

  for (let i = 0; i < routes.length; i++) {
    let route = routes[i];
    // ルートに追加
    processed_routes.push(route);
  }

  return processed_routes;
}

// eslint-disable-next-line no-unused-vars
const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    const selector = location.hash;
    if (selector) {
      // if (
      //   (from.path === "/tokyo/umajo-turfy/" &&
      //     to.path === "/tokyo/forecast/") ||
      //   (from.path === "/tokyo/shops/" && to.path === "/tokyo/forecast/")
      // ) {
      //   return { selector: selector, offset: { x: 0, y: 112 } };
      // }
      return selector;
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: generateRoutes(),
});
export default router;
