<template>
  <div>
    <ul v-if="dataList" class="content__list--inner">
      <li v-for="(item, index) in dataList" :key="index">
        <img
          v-lazy="item.info.img"
          :width="item.width"
          :height="item.height"
          :alt="item.info.alt"
          class="img-camera"
          :class="[item.class, item.name]"
        />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SignageCameraList",
  props: ["dataList"],
};
</script>
