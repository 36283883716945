<template>
  <div class="tabPage tabPage4">
    <div class="content__bar"></div>
    <TmpMessage v-if="tmp" />
    <LiveCamera :dataList="cameraDataList" />
  </div>
</template>

<script>
import LiveCamera from "../detail/LiveCamera.vue";
import TmpMessage from "../message/TmpMessage.vue";

export default {
  name: "TabPaddock",
  components: {
    LiveCamera,
    TmpMessage,
  },
  created() {
    this.checkMessage();
    this.getCameraData(this.cameraDataList);
  },
  data() {
    return {
      tmp: false,
      tabTtlActive: false,
      cameraDataList: {
        type: "type2",
        name: "ライブカメラ画像（パドック）",
        extension: "jpg",
        width: "375",
        height: "211",
        info: {
          alt: "11_パドック",
          camera_no: "11",
          img: "",
        },
      },
      env: process.env.VUE_APP_NODE_ENV,
      envBase: process.env.VUE_APP_2024_2nd_S3_BASE_URL,
      envBaseTmp: process.env.VUE_APP_TMP_S3_BASE_URL,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollWindow);
    this.updateData();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollWindow);
  },
  methods: {
    async checkMessage() {
      await this.$axios
        .get(`${this.envBaseTmp}message.json?` + new Date().getTime())
        .then((response) => {
          // console.log(response);
          this.tmp = response.data.message;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateData() {
      if (this.checkSessionStorageAvailable()) {
        if (sessionStorage.getItem("tabIntervalId")) {
          clearInterval(sessionStorage.getItem("tabIntervalId"));
        }
      }
      this.timeout = setInterval(() => {
        this.getCameraData(this.cameraDataList);
        this.checkMessage();
        // console.log("20秒");
        // }, 5000);
      }, 20000);
      if (this.checkSessionStorageAvailable()) {
        sessionStorage.setItem("tabIntervalId", this.timeout);
      }
    },
    checkSessionStorageAvailable() {
      try {
        sessionStorage.setItem("tabDummy", 1);
        sessionStorage.removeItem("tabDummy");
        return true;
      } catch {
        return false;
      }
    },
    async getCameraData(list) {
      await this.$axios
        .get(
          `${this.envBase}${this.env}/camera_images.json?` +
            new Date().getTime()
        )
        .then((response) => {
          // console.log(response.data);
          this.setCameraImg(response.data, list);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setCameraImg(jsonData, list) {
      const target = list.info;
      const index = jsonData.find(
        (element) => element.camera_no === target.camera_no
      );
      // console.log(index.image_url);
      // console.log(target.img);
      target.img = index.sp_image_url + "?" + new Date().getTime();
    },
    scrollWindow() {
      const tab = 120;
      this.scroll = window.pageYOffset;
      if (tab <= this.scroll) {
        this.tabTtlActive = true;
      } else {
        this.tabTtlActive = false;
      }
    },
  },
};
</script>
