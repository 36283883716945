<template>
  <div class="modal" :class="{ '--show': isShow }">
    <div class="modal__bg" @click.stop="closeModal"></div>
    <div class="modal__body">
      <button class="modal__close--circle" @click.stop="closeModal"></button>
      <div class="modal__title">{{ items.name }}</div>
      <div class="modal__category">{{ items.category }}</div>
      <img
        :src="require(`@/assets/img/sp/food/modal/${items.id}.jpg`)"
        alt=""
        class="modal__img"
      />
      <p class="modal__desc">{{ items.desc }}</p>
      <div class="modal__map" :style="mapStyle">
        <div class="modal__map-wrap" :style="wrapStyle">
          <img
            :src="require(`@/assets/img/sp/food/modal/map/${floor}f.png`)"
            :srcset="`${require(`@/assets/img/sp/food/modal/map/${floor}f@2x.png`)} 2x`"
            alt=""
            class="modal__map-img"
          />
          <img
            src="@/assets/img/sp/food/modal/map/pin.png"
            srcset="@/assets/img/sp/food/modal/map/pin@2x.png 2x"
            alt=""
            :class="`pin --${items.id}`"
          />
        </div>
      </div>
      <div class="modal__footer">
        <button class="modal__close" @click.stop="closeModal">閉じる</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalFood",
  props: ["isShow", "floor", "items", "mapHeight"],
  data() {
    return {
      windowWidth: Number(window.innerWidth) - 60, // 60：マップ左右のマージン＋余白
      mapWidth: 315,
      scale: this.windowWidth / this.mapWidth,
    };
  },
  methods: {
    closeModal() {
      this.$emit("from-modal");
    },
    setScale() {
      this.windowWidth = Number(window.innerWidth) - 60;
      this.scale = this.windowWidth / this.mapWidth;
    },
  },
  computed: {
    wrapStyle() {
      return { transform: `scale(${this.scale})` };
    },
    mapStyle() {
      return { minHeight: `${this.mapHeight * this.scale}px` };
    },
  },
  mounted() {
    window.addEventListener("resize", this.setScale);
    this.setScale();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setScale);
  },
};
</script>
