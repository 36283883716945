<template>
  <div class="tabPage tabPage2">
    <div class="content__bar"></div>
    <TmpMessage v-if="tmp" />
    <div class="food__header" :class="{ fixed: tabTtlActive }">
      <div class="food__notice">
        <img
          class="food__notice-icon"
          src="@/assets/img/sp/icon/icon_tap.png"
          srcset="@/assets/img/sp/icon/icon_tap@2x.png 2x"
          width="17"
          height="26"
          alt=""
        />
        <p class="food__notice-text">
          店舗情報をタップすると<br />店舗位置のMAPなど詳細が表示されます
        </p>
      </div>
      <div class="food__row">
        <div class="food__left --header">
          <div class="food__header-title --2">メモリアル<br />60スタンド</div>
        </div>
        <div class="food__right --header">
          <div class="food__header-title">フジビュースタンド</div>
        </div>
      </div>
    </div>
    <div class="food__main" :class="{ fixed: tabTtlActive }">
      <div v-for="(value, index) in floorData" :key="index">
        <div class="food__row --bar">
          <div
            class="food__left --subtitle"
            :class="{ '--6f': value.id === 6 }"
          >
            <img
              v-lazy="
                require(`@/assets/img/sp/food/subtitle_food_${value.id}.png`)
              "
              :srcset="`${require(`@/assets/img/sp/food/subtitle_food_${value.id}@2x.png`)} 2x`"
              width="33"
              height="37"
              :alt="`${value.id}階`"
            />
          </div>
        </div>
        <FoodList
          :floor="value.id"
          :mapHeight="value.mapHeight"
          :statusList="value.statusListFloor"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FoodList from "../list/FoodList.vue";
import TmpMessage from "../message/TmpMessage.vue";

export default {
  name: "TabFood",
  components: {
    FoodList,
    TmpMessage,
  },
  created() {
    this.testId = this.$router.currentRoute.params.test;
    this.getData(this.testId);
    this.checkMessage();
  },
  data() {
    return {
      testId: null,
      tmp: false,
      tmpHeight: 0,
      tabTtlActive: false,
      floorData: [
        {
          id: 6,
          mapHeight: 197,
          statusListFloor: {
            left: [
              {
                name: "58",
                src: "6f_l_okura",
                info: { alt: "58", img: "status-1" },
                modal: {
                  id: "6f_l_okura",
                  name: "ホテルオークラ",
                  category: "和洋食・中華",
                  desc: "ちょっと贅沢なホテルオークラの本格料理を競馬場で楽しめます。",
                },
              },
            ],
            right: [
              {
                name: "1",
                src: "6f_r_twodays_1",
                info: { alt: "1", img: "status-1" },
                modal: {
                  id: "6f_r_twodays_1",
                  name: "Two days",
                  category: "軽食・ドリンク",
                  desc: "東京競馬場限定のリンゴの香りのハイボール「ベストオブラック」がオススメ。",
                },
              },
              {
                name: "2",
                src: "6f_r_tokiwa",
                info: { alt: "2", img: "status-1" },
                modal: {
                  id: "6f_r_tokiwa",
                  name: "ときわ家",
                  category: "和洋食",
                  desc: "「ときわランチ」「ときわ和定食」など和洋食メニューに、日本酒やワインを多数取り揃えております。",
                },
              },
              {
                name: "3",
                src: "6f_r_twodays_2",
                info: { alt: "3", img: "status-1" },
                modal: {
                  id: "6f_r_twodays_2",
                  name: "Two days",
                  category: "軽食・ドリンク",
                  desc: "東京競馬場限定のリンゴの香りのハイボール「ベストオブラック」がオススメ。",
                },
              },
            ],
          },
        },
        {
          id: 5,
          mapHeight: 207,
          statusListFloor: {
            right: [
              {
                name: "4",
                src: "5f_r_twodays",
                info: { alt: "4", img: "status-1" },
                modal: {
                  id: "5f_r_twodays",
                  name: "Two days",
                  category: "軽食・ドリンク",
                  desc: "東京競馬場限定のリンゴの香りのハイボール「ベストオブラック」がオススメ。",
                },
              },
              {
                name: "5",
                src: "5f_r_belmont",
                info: { alt: "5", img: "status-1" },
                modal: {
                  id: "5f_r_belmont",
                  name: "BELMONT Cafe",
                  category: "ステーキ・ハンバーグ",
                  desc: "「大穴ハンバーグカレー」他、ステーキやハンバーグなどのプレートが豊富。",
                },
              },
              {
                name: "6",
                src: "5f_r_torikoma",
                info: { alt: "6", img: "status-1" },
                modal: {
                  id: "5f_r_torikoma",
                  name: "鳥駒",
                  category: "和食",
                  desc: "鰻と天ぷらが中心の和食レストラン。大きな天ぷらがのった「三連単丼」は必見。",
                },
              },
              {
                name: "7",
                src: "5f_r_musashi",
                info: { alt: "7", img: "status-1" },
                modal: {
                  id: "5f_r_musashi",
                  name: "とん勝 武蔵",
                  category: "とんかつ・和食",
                  desc: "定食や「かつ重」のほか、「カツカレー弁当」「ロースかつ弁当」などテイクアウトメニューも多数。",
                },
              },
              {
                name: "8",
                src: "5f_r_kandagawa",
                info: { alt: "8", img: "status-1" },
                modal: {
                  id: "5f_r_kandagawa",
                  name: "神田川",
                  category: "鰻・和食",
                  desc: "活鰻を仕入れ、職人技で1尾ずつ丁寧に蒲焼に仕上げた「鰻特重」をぜひお楽しみください。",
                },
              },
              {
                name: "9",
                src: "5f_r_goodsmile",
                info: { alt: "9", img: "status-1" },
                modal: {
                  id: "5f_r_goodsmile",
                  name: "good smile",
                  category: "軽食・ドリンク",
                  desc: "蹄鉄の焼印入り「肉まん」など競馬場らしい商品を販売中。",
                },
              },
              {
                name: "10",
                src: "5f_r_iso",
                info: { alt: "10", img: "status-1" },
                modal: {
                  id: "5f_r_iso",
                  name: "お食事処 磯",
                  category: "和洋中",
                  desc: "昔ながらの鶏ガラスープと自家製チャーシューの「醬油ラーメン」が幅広い年代に人気。",
                },
              },
            ],
          },
        },
        {
          id: 4,
          mapHeight: 223,
          statusListFloor: {
            right: [
              {
                name: "16",
                src: "4f_r_twodays",
                info: { alt: "16", img: "status-1" },
                modal: {
                  id: "4f_r_twodays",
                  name: "Two days",
                  category: "軽食・ドリンク",
                  desc: "プレミアム生クリームソフト「クレミア」を販売中。東京競馬場限定のリンゴの香りのハイボール「ベストオブラック」もオススメ。",
                },
              },
              {
                name: "17",
                src: "4f_r_jindaiji",
                info: { alt: "17", img: "status-1" },
                modal: {
                  id: "4f_r_jindaiji",
                  name: "馬そば深大寺",
                  category: "そば・うどん",
                  desc: "「かき揚げそば」や「いかゲソ天そば」は常連の競馬ファンに大人気。",
                },
              },
              {
                name: "18",
                src: "4f_r_gindako",
                info: { alt: "18", img: "status-1" },
                modal: {
                  id: "4f_r_gindako",
                  name: "築地銀だこハイボール酒場",
                  category: "たこ焼き",
                  desc: "たこ焼きとハイボールがセットになったGⅡ角ハイセットがオススメ。",
                },
              },
              {
                name: "19",
                src: "4f_r_sanko_1",
                info: { alt: "19", img: "status-1" },
                modal: {
                  id: "4f_r_sanko_1",
                  name: "三幸",
                  category: "軽食・ドリンク",
                  desc: "店内で丁寧に手作りしたお餅のいそべ巻やおしるこを、お楽しみください。",
                },
              },
              {
                name: "20",
                src: "4f_r_sanko_2",
                info: { alt: "20", img: "status-1" },
                modal: {
                  id: "4f_r_sanko_2",
                  name: "三幸",
                  category: "からあげ親子丼・勝つ重",
                  desc: "サクサクの竜田あげに、あんかけ風の玉子だしをかけた「からあげ親子丼」が人気。",
                },
              },
            ],
          },
        },
        {
          id: 3,
          mapHeight: 220,
          statusListFloor: {
            left: [
              {
                name: "62",
                src: "3f_l_newtokyo",
                info: { alt: "62", img: "status-1" },
                modal: {
                  id: "3f_l_newtokyo",
                  name: "ニユー・トーキヨー",
                  category: "和洋食",
                  desc: "サラダ付きの「カツカレーセット」が人気。お子様向けの「キッズプレート」もあります。",
                },
              },
              {
                name: "61",
                src: "3f_l_sapporo",
                info: { alt: "61", img: "status-1" },
                modal: {
                  id: "3f_l_sapporo",
                  name: "サッポロドリンクコーナー",
                  category: "ドリンク",
                  desc: "お酒・ドリンク類のほか、おつまみやポテトフライなどの軽食も販売中。",
                },
              },
            ],
            right: [
              {
                name: "21",
                src: "3f_r_tokyocafe",
                info: {
                  alt: "21",
                  img: "status-1",
                },
                modal: {
                  id: "3f_r_tokyocafe",
                  name: "トーキョーカフェ＆ベーカリー",
                  category: "パン・ドリンク",
                  desc: "東京競馬場名物「大穴ドーナツ」が大人気☆",
                },
              },
              {
                name: "22",
                src: "3f_r_pepper",
                info: { alt: "22", img: "status-1" },
                modal: {
                  id: "3f_r_pepper",
                  name: "ペッパーランチ",
                  category: "ステーキ",
                  desc: "東京競馬場店限定「サーロインステーキ重」はコスパ最強。",
                },
              },
              {
                name: "23",
                src: "3f_r_bar2400",
                info: { alt: "23", img: "status-1" },
                modal: {
                  id: "3f_r_bar2400",
                  name: "BAR2400@TOKYO",
                  category: "カクテル・ドリンク",
                  desc: "競走馬やレースをイメージしたオリジナルカクテルを期間限定販売中。",
                },
              },
              {
                name: "24",
                src: "3f_r_first",
                info: { alt: "24", img: "status-1" },
                modal: {
                  id: "3f_r_first",
                  name: "ファーストキッチン",
                  category: "ハンバーガー",
                  desc: "人気商品「ダブルチーズベーコンエッグバーガー」のベーコンエッグは店内で焼いています。",
                },
              },
              {
                name: "25",
                src: "3f_r_pizzala",
                info: { alt: "25", img: "status-1" },
                modal: {
                  id: "3f_r_pizzala",
                  name: "ピザーラエクスプレス",
                  category: "ピザ",
                  desc: "お好きなピザ1ピース+フライドポテト+生ビールの「本命セット」がお得。",
                },
              },
              {
                name: "27",
                src: "3f_r_cavallo",
                info: { alt: "27", img: "status-1" },
                modal: {
                  id: "3f_r_cavallo",
                  name: "CAFE Cavallo",
                  category: "カフェ・パスタ",
                  desc: "パスタは約20種類！女性やお子様もゆったり楽しめるカフェ。",
                },
              },
              {
                name: "26",
                src: "3f_r_seven",
                info: { alt: "26", img: "status-1" },
                modal: {
                  id: "3f_r_seven",
                  name: "セブン-イレブン",
                  category: "コンビニ",
                  desc: "「セブンカフェ」などフード・ドリンクの他、競馬場ならではの雑貨や衛生用品も取り扱っています。",
                },
              },
              {
                name: "28",
                src: "3f_r_excel",
                info: { alt: "28", img: "status-1" },
                modal: {
                  id: "3f_r_excel",
                  name: "エクセルシオールカフェ",
                  category: "カフェ",
                  desc: "ホットドッグ、アルコール各種、プレミアム生クリームソフト「クレミア」も販売してます。",
                },
              },
            ],
          },
        },
        {
          id: 2,
          mapHeight: 237,
          statusListFloor: {
            left: [
              {
                name: "66",
                src: "2f_l_twodays",
                info: { alt: "66", img: "status-1" },
                modal: {
                  id: "2f_l_twodays",
                  name: "Two days",
                  category: "軽食・ドリンク",
                  desc: "東京競馬場限定のリンゴの香りのハイボール「ベストオブラック」がオススメ。",
                },
              },
              {
                name: "65",
                src: "2f_l_tomatsu",
                info: { alt: "65", img: "status-1" },
                modal: {
                  id: "2f_l_tomatsu",
                  name: "戸松",
                  category: "からあげ",
                  desc: "「ももスペシャル」はボリューム満点で食べごたえあり◎",
                },
              },
              {
                name: "64",
                src: "2f_l_yanagi",
                info: { alt: "64", img: "status-1" },
                modal: {
                  id: "2f_l_yanagi",
                  name: "やなぎ",
                  category: "そば・うどん",
                  desc: "野菜たっぷりのかき揚げがのった「天ぷらそば」や、豚トロを使用した「肉そば」が人気。",
                },
              },
            ],
            right: [
              {
                name: "40",
                src: "2f_r_kasuya",
                info: { alt: "40", img: "status-1" },
                modal: {
                  id: "2f_r_kasuya",
                  name: "KASUYA",
                  category: "かすうどん・どんぶり",
                  desc: "関西名物「かすうどん」が東京競馬場に。ホルモン焼きや丼ものメニューも販売中。",
                },
              },
              {
                name: "41",
                src: "2f_r_tonya",
                info: { alt: "41", img: "status-1" },
                modal: {
                  id: "2f_r_tonya",
                  name: "豚や",
                  category: "豚料理",
                  desc: "トロットロの角煮がゴロリと乗った「豚角煮丼」が大人気！",
                },
              },
              {
                name: "38",
                src: "2f_r_koichiro",
                info: { alt: "38", img: "status-1" },
                modal: {
                  id: "2f_r_koichiro",
                  name: "耕一路",
                  category: "喫茶",
                  desc: "大人気の「モカソフト」、「モカフロート」等を販売中。",
                },
              },
              {
                name: "39",
                src: "2f_r_fukumitsu",
                info: { alt: "39", img: "status-1" },
                modal: {
                  id: "2f_r_fukumitsu",
                  name: "福三（ふくみつ）",
                  category: "焼きそば",
                  desc: "競馬場メシといえば福三の「もつ煮」。",
                },
              },
              {
                name: "42",
                src: "2f_r_bajitofu",
                info: { alt: "42", img: "status-1" },
                modal: {
                  id: "2f_r_bajitofu",
                  name: "馬耳東風軒",
                  category: "中華",
                  desc: "しびれる辛さとしっかりした旨味の「麻婆豆腐丼」が名物メニュー。",
                },
              },
              {
                name: "43",
                src: "2f_r_kokoro",
                info: { alt: "43", img: "status-1" },
                modal: {
                  id: "2f_r_kokoro",
                  name: "ココロ焦がれ",
                  category: "どんぶり",
                  desc: "「牛ハラミ焼肉丼」、「温玉からあげ丼」など肉系メニューが充実。",
                },
              },
              {
                name: "44",
                src: "2f_r_fukumitsu_1",
                info: { alt: "44", img: "status-1" },
                modal: {
                  id: "2f_r_fukumitsu_1",
                  name: "ふくみつ",
                  category: "焼きそば・軽食",
                  desc: "昔懐かしい味わいの「焼きそば」は大人気。",
                },
              },
              {
                name: "45",
                src: "2f_r_horse",
                info: { alt: "45", img: "status-1" },
                modal: {
                  id: "2f_r_horse",
                  name: "ホースアイ",
                  category: "軽食・ドリンク",
                  desc: "旬のフルーツソフト、本格石焼シルクスイートの他、ビールのお供が充実！",
                },
              },
              {
                name: "46",
                src: "2f_r_fukumitsu_2",
                info: { alt: "46", img: "status-1" },
                modal: {
                  id: "2f_r_fukumitsu_2",
                  name: "ふくみつ",
                  category: "焼きそば・軽食",
                  desc: "パリパリの麺に野菜たっぷりの餡がかかった「皿うどん」がイチ押し。",
                },
              },
              {
                name: "47",
                src: "2f_r_umeya",
                info: { alt: "47", img: "status-1" },
                modal: {
                  id: "2f_r_umeya",
                  name: "梅屋",
                  category: "そば・うどん",
                  desc: "「肉南蛮そば・うどん」はメディアでも紹介された自慢の一品。",
                },
              },
            ],
          },
        },
        {
          id: 1,
          mapHeight: 236,
          statusListFloor: {
            right: [
              {
                name: "51",
                src: "1f_r_inamatsu",
                info: { alt: "51", img: "status-1" },
                modal: {
                  id: "1f_r_inamatsu",
                  name: "カジュアル稲松",
                  category: "和洋食",
                  desc: "おすすめは「焼きそば・生ビール小セット(漬物付き)」、ちょい呑みにいかがですか。",
                },
              },
              {
                name: "52",
                src: "1f_r_twodays",
                info: { alt: "52", img: "status-1" },
                modal: {
                  id: "1f_r_twodays",
                  name: "Two days",
                  category: "小籠包・台湾料理",
                  desc: "東京競馬場限定のリンゴの香りのハイボール「ベストオブラック」がオススメ。",
                },
              },
              {
                name: "53",
                src: "1f_r_moriyama",
                info: { alt: "53", img: "status-1" },
                modal: {
                  id: "1f_r_moriyama",
                  name: "中津からあげもり山",
                  category: "からあげ",
                  desc: "「鶏飯おにぎりからあげセット」はおにぎり2個+からあげ4個でお得＆ボリュームも十分。",
                },
              },
              {
                name: "54",
                src: "1f_r_joy",
                info: { alt: "54", img: "status-1" },
                modal: {
                  id: "1f_r_joy",
                  name: "JOY",
                  category: "軽食・ドリンク",
                  desc: "大人なカレーが好きな方には、「カレー(キーマカレー)」をオススメ。",
                },
              },
              {
                name: "55",
                src: "1f_r_tonya",
                info: { alt: "55", img: "status-1" },
                modal: {
                  id: "1f_r_tonya",
                  name: "豚や",
                  category: "豚料理・ドリンク",
                  desc: "お酒にぴったり！「豚やの牛ホルモン焼き」が食べられるのはこちら。",
                },
              },
            ],
          },
        },
      ],
      jsonList: [],
      env: process.env.VUE_APP_NODE_ENV,
      envBase: process.env.VUE_APP_2024_2nd_S3_BASE_URL,
      envBaseTmp: process.env.VUE_APP_TMP_S3_BASE_URL,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollWindow);
    this.updateData();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollWindow);
  },
  methods: {
    async checkMessage() {
      await this.$axios
        .get(`${this.envBaseTmp}message.json?` + new Date().getTime())
        .then((response) => {
          this.tmp = response.data.message;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getData(id) {
      const jsonURL =
        id === "99"
          ? `https://stg.jra-keibajo-konzatsu.jp/status_axis_1.json?`
          : `${this.envBase}${this.env}/congestion/axis_1.json?`;
      await this.$axios
        .get(jsonURL + new Date().getTime())
        .then((response) => {
          this.jsonList = response.data;
          this.searchData(this.jsonList);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchData() {
      for (let item of Object.entries(this.floorData || {})) {
        const listDataLeft = item[1].statusListFloor.left;
        if (listDataLeft !== undefined) {
          for (const i of listDataLeft) {
            // FIXME AXIS確認
            this.divideData(i, this.jsonList);
          }
        }
        const listDataRight = item[1].statusListFloor.right;
        if (listDataRight !== undefined) {
          for (const i of listDataRight) {
            // FIXME AXIS確認
            this.divideData(i, this.jsonList);
          }
        }
      }
    },
    divideData(i, jsonData) {
      let index = jsonData.find((element) => element.camera_no === i.name);
      if (index !== undefined) {
        const status = index.status;
        i.info.name = index.camera_no;
        if (status === 2) {
          i.info.img = "status-5";
        } else if (status === 3) {
          i.info.img = "status-6";
        } else {
          this.setImg(index.congestion_level, i);
        }
      }
    },
    setImg(level, i) {
      if (level) {
        i.info.img = `status-${level}`;
      }
    },
    updateData() {
      if (this.checkSessionStorageAvailable()) {
        if (sessionStorage.getItem("tabIntervalId")) {
          clearInterval(sessionStorage.getItem("tabIntervalId"));
        }
      }
      this.timeout = setInterval(() => {
        this.getData(this.testId);
        this.checkMessage();
      }, 20000);
      // }, 1000);
      if (this.checkSessionStorageAvailable()) {
        sessionStorage.setItem("tabIntervalId", this.timeout);
      }
    },
    checkSessionStorageAvailable() {
      try {
        sessionStorage.setItem("tabDummy", 1);
        sessionStorage.removeItem("tabDummy");
        return true;
      } catch {
        return false;
      }
    },
    scrollWindow() {
      if (this.tmp) {
        this.tmpHeight =
          document.getElementById("tmp-message").offsetHeight + 40; // 40：上下マージン
      }
      const tab = 135 + this.tmpHeight;
      this.scroll = window.pageYOffset;
      if (tab <= this.scroll) {
        this.tabTtlActive = true;
      } else {
        this.tabTtlActive = false;
      }
    },
  },
};
</script>
