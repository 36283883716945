<template>
  <div>
    <SpHeader :text="text" />
    <LinkHeader
      :left="headerLink.left"
      :right="headerLink.right"
      :show="headerLink.show"
    />
    <div class="toppage-tab-contents-on-site">
      <div class="tabPage tabPage4">
        <TmpMessage v-if="tmp" />
        <MapParking :statusList="statusListParking" />
        <ol class="parking__list">
          <li
            v-for="(value, key) in statusListParking"
            :key="key"
            class="parking__list-item"
          >
            <img
              v-lazy="
                require(`@/assets/img/sp/status/parking/${value.info.img}.png`)
              "
              :srcset="`${require(`@/assets/img/sp/status/parking/${value.info.img}@2x.png`)} 2x`"
              :alt="value.info.alt"
            />
            <img
              v-lazy="
                require(`@/assets/img/sp/parking/parking-info-${value.no}.png`)
              "
              :srcset="`${require(`@/assets/img/sp/parking/parking-info-${value.no}@2x.png`)} 2x`"
              :alt="`${value.info.alt}情報`"
            />
          </li>
        </ol>
        <div class="parking__fee">
          <img
            src="@/assets/img/sp/parking/img-fee.png"
            srcset="@/assets/img/sp/parking/img-fee@2x.png 2x"
            width="124"
            height="34"
            alt="駐車料金2,000円"
            class="parking__fee-img"
          />
        </div>
        <LegendParking />
      </div>
    </div>
    <SpFooter />
    <ModalOutOfTerm :isShow="isModalShow" @from-modal="closeModal" />
  </div>
</template>

<script>
import SpFooter from "../../components/common/Footer.vue";
import SpHeader from "../../components/common/Header.vue";
import TmpMessage from "../../components/message/TmpMessage.vue";
import LinkHeader from "../../components/detail/LinkHeader.vue";
import LegendParking from "../../components/detail/LegendParking.vue";
import MapParking from "../../components/detail/MapParking.vue";
import ModalOutOfTerm from "../../components/detail/ModalOutOfTerm.vue";

export default {
  name: "SpParking",
  components: {
    SpHeader,
    TmpMessage,
    SpFooter,
    LinkHeader,
    LegendParking,
    MapParking,
    ModalOutOfTerm,
  },
  created() {
    this.checkParkingNo();
    this.checkMessage();
    this.checkHeaderLink();
    this.checkParkwins();
  },
  data() {
    return {
      text: "parking",
      tmp: false,
      parkingNo: null,
      isModalShow: false,
      headerLink: {
        left: {
          external: false,
          to: "/tokyo/shops/",
          type: "shop",
          icon: { name: "shop", width: "24", height: "25" },
          text: {
            title: "飲食店などの\n混雑状況",
          },
        },
        right: {},
        // アンケート実施日
        survey: {
          external: true,
          to: "/",
          type: "survey",
          icon: { name: "gift", width: "17", height: "17" },
          text: {
            title: "アンケートはこちら",
            sub: "回答するとデジタルギフトが当たる",
          },
        },
        // トイレ混雑
        toilet: {
          external: false,
          to: "/tokyo/toilet/",
          type: "toilet",
          icon: { name: "woman", width: "13", height: "28" },
          text: {
            title: "女性用トイレの\n混雑状況",
          },
        },
        show: false,
      },
      statusListParking: [
        {
          no: 1,
          info: { alt: "第1駐車場", img: "status-1" },
        },
        {
          no: 2,
          info: { alt: "第2駐車場", img: "status-2" },
        },
        {
          no: 4,
          info: { alt: "第4駐車場", img: "status-3" },
        },
      ],
      env: process.env.VUE_APP_NODE_ENV,
      envBase: process.env.VUE_APP_2024_2nd_S3_BASE_URL,
      envBaseTmp: process.env.VUE_APP_TMP_S3_BASE_URL,
    };
  },
  mounted() {
    this.updateData();
  },
  methods: {
    async checkMessage() {
      await this.$axios
        .get(`${this.envBaseTmp}message.json?` + new Date().getTime())
        .then((response) => {
          // console.log(response);
          this.tmp = response.data.message;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async checkHeaderLink() {
      await this.$axios
        .get(`${this.envBaseTmp}header_link.json?` + new Date().getTime())
        .then((response) => {
          // console.log(response);
          const today = new Date();
          const checkSurveyDisplay = (element) => {
            const surveyDay = new Date(element);
            return (
              today.getFullYear() === surveyDay.getFullYear() &&
              today.getMonth() === surveyDay.getMonth() &&
              today.getDate() === surveyDay.getDate()
            );
          };
          if (response.data.some(checkSurveyDisplay)) {
            this.headerLink.right = this.headerLink.survey;
          } else {
            this.headerLink.right = this.headerLink.toilet;
          }
          this.headerLink.show = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async checkParkwins() {
      await this.$axios
        .get(`${this.envBaseTmp}parkwins.json?` + new Date().getTime())
        .then((response) => {
          // console.log(response);
          const today = new Date();
          const checkDateMatch = (element) => {
            const checkDay = new Date(element);
            return (
              today.getFullYear() === checkDay.getFullYear() &&
              today.getMonth() === checkDay.getMonth() &&
              today.getDate() === checkDay.getDate()
            );
          };
          if (this.checkSessionStorageAvailable()) {
            if (response.data.some(checkDateMatch)) {
              const todayStr =
                today.getFullYear().toString() +
                "/" +
                (today.getMonth() + 1).toString().padStart(2, "0") +
                "/" +
                today.getDate().toString().padStart(2, "0");
              if (sessionStorage.getItem("parkwins")) {
                if (sessionStorage.getItem("parkwins") !== todayStr) {
                  this.showModal();
                  sessionStorage.setItem("parkwins", todayStr);
                }
              } else {
                this.showModal();
                sessionStorage.setItem("parkwins", todayStr);
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkParkingNo() {
      for (const item of this.statusListParking) {
        this.parkingNo = item.no;
        this.getManualChangeData(item);
      }
    },
    async getManualChangeData(item) {
      await this.$axios
        .get(
          `${this.envBase}${this.env}/manual/parking_id_${this.parkingNo}.json?` +
            new Date().getTime()
        )
        .then((response) => {
          this.setImg(response.data[0].congestion_status, item.info);
          //デバッグ用
          // item.info.name = response.data[0];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setImg(level, i) {
      if (level || level === 0) {
        i.img = `status-${level}`;
      }
    },
    updateData() {
      if (this.checkSessionStorageAvailable()) {
        if (sessionStorage.getItem("tabIntervalId")) {
          clearInterval(sessionStorage.getItem("tabIntervalId"));
        }
      }
      this.timeout = setInterval(() => {
        this.checkParkingNo();
        this.checkMessage();
        // console.log("20秒");
        // }, 5000);
      }, 20000);
      if (this.checkSessionStorageAvailable()) {
        sessionStorage.setItem("tabIntervalId", this.timeout);
      }
    },
    checkSessionStorageAvailable() {
      try {
        sessionStorage.setItem("tabDummy", 1);
        sessionStorage.removeItem("tabDummy");
        return true;
      } catch {
        return false;
      }
    },
    showModal() {
      this.isModalShow = true;
      const $body = document.getElementsByTagName("body")[0];
      $body.classList.add("fixed");
    },
    closeModal() {
      this.isModalShow = false;
      const $body = document.getElementsByTagName("body")[0];
      $body.classList.remove("fixed");
    },
  },
};
</script>
