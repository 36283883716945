<template>
  <footer>
    <div class="footer__button-pagetop">
      <div @click="returnTop">
        <IconPagetop />
      </div>
    </div>
    <div class="footer__container">
      <div class="footer__inner">
        <p class="footer__title">混雑情報の提供に関するお知らせ</p>
        <ul class="footer__notify">
          <li>混雑情報の提供は、一部エリアを除いて実施しております。</li>
          <li>実際の混雑状況と必ずしも一致するものではありません。</li>
          <li>
            表示の際は、個人の特定につながらないよう加工等の措置を講じています。
          </li>
          <li>
            状況によってサイネージ端末の設置場所が変更となる可能性があります。
          </li>
        </ul>
        <ul class="footer__notify dot-large">
          <li>馬券は20歳になってから&nbsp;&nbsp;ほどよく楽しむ大人の遊び。</li>
          <li>競馬場、ウインズへは電車・バスで。</li>
          <li>馬券は正規の窓口で。</li>
        </ul>
        <div class="footer__image">
          <img
            src="@/assets/img/common/jra.png"
            srcset="@/assets/img/common/jra@2x.png 2x"
            width="93"
            height="35"
            alt="JRAのロゴ"
          />
        </div>
        <p class="footer__copyright">
          Copyright &copy; Japan Racing Association All rights reserved.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import IconPagetop from "@/components/icon/Pagetop";

export default {
  name: "SpFooter",
  components: {
    IconPagetop,
  },
  data() {
    return {
      buttonActive: false,
      scroll: 0,
      isAbsolute: false,
    };
  },
  methods: {
    returnTop() {
      this.checkPath();
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    checkPath() {
      let path = this.$router.currentRoute.path;
      if (this.$router.currentRoute.hash) {
        this.$router.push({ path });
      }
    },
  },
};
</script>
