<template>
  <i class="btn-pagetop"></i>
</template>

<script>
export default {
  name: "IconPagetop",
};
</script>

<style lang="scss" scoped>
.btn-pagetop {
  display: inline-block;
  width: 60px;
  height: 60px;
  background-image: url(~@/assets/img/sp/btn-pagetop.png);
  background-size: contain;
  background-repeat: no-repeat;
  transition-duration: 0.3s;
  &:hover {
    opacity: 0.7;
  }
}
</style>