<template>
  <div class="content__data">
    <div v-if="statusList" class="content__list--signage">
      <ul>
        <li
          v-for="(item, index) in statusList.data"
          class="content__item"
          :class="`content__item--${item.name}`"
          :key="index"
        >
          <div
            class="iframe-container"
            :class="`iframe-container--${item.name} ${item.category}`"
          >
            <img
              v-if="item.img"
              v-lazy="
                require(`@/assets/img/signage/status/${item.category}/${item.img}.png`)
              "
              :srcset="`${require(`@/assets/img/signage/status/${item.category}/${item.img}@2x.png`)} 2x`"
              :class="item.class"
              :width="item.width"
              :height="item.height"
              :alt="item.alt"
            />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignageDataList",
  props: ["statusList"],
};
</script>
