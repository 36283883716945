<template>
  <div class="tabPage tabPage3">
    <div class="content__bar"></div>
    <TmpMessage v-if="tmp" />
    <ul class="childtabs" :class="{ fixed: tabTtlActive }">
      <li class="tab">
        <a href="#umajo">UMAJO SPOT</a>
      </li>
      <li class="tab">
        <a href="#turfy">ターフィーショップ</a>
      </li>
    </ul>
    <div v-for="(value, key) in floorData" :key="key">
      <span
        class="floor-container"
        :id="(value || {}).floor"
        :class="{ fixed: value.fixed ? tabTtlActive : null }"
      ></span>
      <div
        class="content__label"
        :class="value.rows ? 'content__label--rows' : null"
        v-if="value.floorName"
      >
        {{ value.floorName }}
        <span v-if="value.floorSubTitle" class="content__supLabel">
          {{ value.floorSubTitle }}
        </span>
        <div
          v-if="value.floorNoticeText"
          class="text-container content__notify"
        >
          {{ value.floorNoticeText }}
        </div>
      </div>
      <data-list
        :dataList="value.dataList"
        :statusList="value.statusListFloor"
        :className="`${classNameFloor}${value.id}`"
      />
    </div>
  </div>
</template>

<script>
import DataList from "../list/DataList.vue";
import TmpMessage from "../message/TmpMessage.vue";

export default {
  name: "TabOther",
  components: {
    DataList,
    TmpMessage,
  },
  created() {
    this.checkShopId();
    this.checkMessage();
  },
  mounted() {
    window.addEventListener("scroll", this.scrollWindow);
    this.updateData();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollWindow);
  },
  data() {
    return {
      tmp: false,
      tmpHeight: 0,
      tabTtlActive: false,
      classNameFloor: "floor",
      shopId: null,
      floorData: [
        {
          id: 1,
          floor: "umajo",
          floorName: "UMAJO SPOT",
          fixed: true,
          dataList: {
            map: {
              image: {
                src: "other/map-5-f",
                width: "355",
                height: "118",
                alt: "フジビュースタンド1階のマップ",
              },
            },
          },
          statusListFloor: {
            id: "other",
            column: "column2",
            path: "other",
            data: [
              {
                manual: true,
                id: 1,
                hash: "umajo-plus",
                hasForecast: false,
                // FIXME注意
                category: "other",
                // category: "other/umajo",
                src: "umajo-plus",
                width: "172",
                height: "194",
                alt: "04_UMAJO SPOT PLUS",
                class: "frame_center",
                info: { alt: "04_UMAJO SPOT PLUS", img: "status-1" },
              },
              {
                id: 2,
                hash: "umajo",
                hasForecast: false,
                category: "other",
                src: "umajo",
                width: "172",
                height: "194",
                alt: "UMAJO SPOT",
                class: "frame_center",
                info: { alt: "", img: "status-1" },
              },
            ],
          },
        },
        {
          id: 3,
          floor: "turfy",
          floorName: "タ−フィ−ショップ",
          floorSubTitle: "（グッズ販売）",
          // rows: true,
          // floorNoticeText:
          //   "一部店舗ではGⅠ当日における混雑予測の目安をご覧いただけます",
          fixed: false,
          dataList: {
            map: {
              image: {
                src: "other/map-3-f",
                width: "355",
                height: "138",
                alt: "フジビュースタンド1階のマップ",
              },
            },
          },
          statusListFloor: {
            id: "other",
            column: "column1",
            path: "other",
            data: [
              {
                id: 3,
                hash: "turfy3_fuji",
                hasForecast: false,
                category: "other",
                src: "turfy3_fuji",
                width: "172",
                height: "199",
                alt: "ターフィーショップ 3階 フジビュー",
                class: "frame_center",
                info: { alt: "", img: "status-1" },
              },
            ],
          },
        },
        {
          id: 2,
          fixed: false,
          dataList: {
            map: {
              image: {
                src: "other/map-2-f",
                width: "355",
                height: "134",
                alt: "フジビュースタンド1階のマップ",
              },
            },
          },
          statusListFloor: {
            id: "other",
            column: "column2",
            path: "other",
            data: [
              {
                id: 5,
                hash: "turfy2_mem",
                hasForecast: false,
                category: "other",
                src: "turfy2_mem",
                width: "172",
                height: "199",
                alt: "ターフィーショップ 2階 メモリアル",
                class: "frame_center",
                info: { alt: "", img: "status-1" },
              },
              {
                id: 4,
                hash: "turfy2_fuji",
                hasForecast: false,
                category: "other",
                src: "turfy2_fuji",
                width: "172",
                height: "199",
                alt: "ターフィーショップ 2階 フジビュー",
                class: "frame_center",
                info: { alt: "", img: "status-1" },
              },
            ],
          },
        },
      ],
      env: process.env.VUE_APP_NODE_ENV,
      envBase: process.env.VUE_APP_2024_2nd_S3_BASE_URL,
      envBaseTmp: process.env.VUE_APP_TMP_S3_BASE_URL,
    };
  },
  methods: {
    async checkMessage() {
      await this.$axios
        .get(`${this.envBaseTmp}message.json?` + new Date().getTime())
        .then((response) => {
          this.tmp = response.data.message;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setImg(level, i) {
      if (level || level === 0) {
        i.img = `status-${level}`;
      }
    },
    checkShopId() {
      for (let item of Object.entries(this.floorData || {})) {
        // const listData = item[1];
        const setList = item[1].statusListFloor.data;
        // console.log(setList);
        for (const i of setList) {
          // console.log(i.id);
          this.shopId = i.id;
          this.getManualChangeData(i);
          // if (i.id) {
          //   this.getManualChangeData(i);
          // } else if (i.manual) {
          //   this.getData(i);
          // }
        }
      }
    },
    async getManualChangeData(item) {
      await this.$axios
        .get(
          `${this.envBase}${this.env}/manual/shop_id_${this.shopId}.json?` +
            new Date().getTime()
        )
        .then((response) => {
          this.setImg(response.data[0].congestion_status, item.info);
          //デバッグ用
          // item.info.name = response.data[0];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateData() {
      if (this.checkSessionStorageAvailable()) {
        if (sessionStorage.getItem("tabIntervalId")) {
          clearInterval(sessionStorage.getItem("tabIntervalId"));
        }
      }
      this.timeout = setInterval(() => {
        this.checkShopId();
        this.checkMessage();
      }, 20000);
      if (this.checkSessionStorageAvailable()) {
        sessionStorage.setItem("tabIntervalId", this.timeout);
      }
    },
    checkSessionStorageAvailable() {
      try {
        sessionStorage.setItem("tabDummy", 1);
        sessionStorage.removeItem("tabDummy");
        return true;
      } catch {
        return false;
      }
    },
    scrollWindow() {
      if (this.tmp) {
        this.tmpHeight =
          document.getElementById("tmp-message").offsetHeight + 40;
      }
      const tab = 134 + this.tmpHeight;
      this.scroll = window.pageYOffset;
      if (tab <= this.scroll) {
        this.tabTtlActive = true;
      } else {
        this.tabTtlActive = false;
      }
    },
  },
};
</script>
