<template>
  <header>
    <div class="header__container">
      <div class="header__image">
        <img
          src="@/assets/img/common/jra.png"
          srcset="@/assets/img/common/jra@2x.png 2x"
          width="78"
          height="29"
          alt="JRAのロゴ"
        />
      </div>
      <!-- <p class="header__text">実証実験中</p> -->
      <p class="header__title" v-if="text === 'tab'">
        <img
          src="@/assets/img/common/header_title.png"
          srcset="@/assets/img/common/header_title@2x.png 2x"
          width="350"
          height="16"
          alt="東京競馬場フジビュー/メモリアルスタンド 混雑状況"
        />
      </p>
      <p class="header__title" v-else-if="text === 'toilet'">
        <img
          src="@/assets/img/common/header_title_toilet.png"
          srcset="@/assets/img/common/header_title_toilet@2x.png 2x"
          width="249"
          height="16"
          alt="東京競馬場 女性用トイレ 混雑状況"
        />
      </p>
      <p class="header__title" v-else-if="text === 'parking'">
        <img
          src="@/assets/img/common/header_title_parking.png"
          srcset="@/assets/img/common/header_title_parking@2x.png 2x"
          width="233"
          height="16"
          alt="東京競馬場 場外駐車場 混雑状況"
        />
      </p>
      <p v-else class="header__title">
        <img
          src="@/assets/img/common/header_title_forecast.png"
          srcset="@/assets/img/common/header_title_forecast@2x.png 2x"
          width="344"
          height="14"
          alt="東京競馬場 飲食店/ターフィーショップ/UMAJO 混雑予測"
        />
      </p>
    </div>
  </header>
</template>
<script>
export default {
  name: "SpHeader",
  props: {
    text: String,
  },
};
</script>
<style lang="scss" scoped></style>
