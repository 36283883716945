<template>
  <div>
    <!-- ヘッダ -->
    <h1 class="title">
      <img
        class="title__logo"
        src="@/assets/img/admin/jra_logo.svg"
        alt="JRAロゴ"
      />混雑状況 営業時間設定
    </h1>
    <!-- /ヘッダ -->

    <!-- 強制営業時間設定 -->
    <div class="top-bar">
      <div class="top-bar__id">営業時間設定：</div>

      <!-- 有効、無効-->
      <label class="button__toggle --primary">
        <input type="checkbox" v-model="openTimeFlg" @change="handleOpenTimeFlgChange" />
      </label>

      <!-- 強制営業時間タイプ [営業時間内、営業時間外]-->
      <div class="top-bar__item top-bar__item--select">
        <select
          :disabled="!openTimeFlg"
          name=""
          id=""
          v-model="forceOpenType"
        >
          <option value="force_open" selected>強制全営業時間内</option>
          <option value="force_close">強制全営業時間外</option>
        </select>
      </div>
      <button class="button --small --border" @click="btnClickForceUpdate">更新する</button>
    </div>
    <!-- /強制営業時間設定 -->

    <div class="form">

      <!-- 設定切り替えタブ -->
      <ul class="tabs">
        <li v-for="(d, key) in tabList" v-bind:key="key"
          @click="changeTab(d.tab_id)"
          class="tab"
          :class="{ active: currentTabId == d.tab_id }"
        >{{d.label}}
        </li>
      </ul>
      <!-- /設定切り替えタブ -->

      <ul class="contents">

        <!-- 入力 [タイトル、個別設定対象日付、設定有効化] -->
        <ul class="setting-list">

          <!-- タイトル（タブ1以外） -->
          <li class="setting-item" v-show="currentTabId !== 1">
            <div class="top-bar --primary">
              <div class="top-bar__id">タイトル：</div>
              <input
                name="title"
                type="text"
                class="top-bar__item js-input"
                required="required"
                v-model="tabTitle"
              />
            </div>
          </li>

          <!-- 個別設定対象日付（タブ1以外） -->
          <li class="setting-item" v-show="currentTabId !== 1">
            <div class="top-bar --primary">
              <div class="top-bar__id">個別設定対象日付：</div>
              <datepicker
                name="customize_date"
                class="top-bar__datepicker"
                required="required"
                v-model="tabCustomizeDate"
                :language="ja"
                :format="DatePickerFormat">
              </datepicker>
            </div>
          </li>

          <!-- 設定有効化（タブ1以外） -->
          <li class="setting-item" v-show="currentTabId !== 1">
            <div class="top-bar --primary">
              <div class="top-bar__id">設定有効化：</div>
              <div class="top-bar__radio-wrap">
                <div class="top-bar__radio">
                  <input class="radiobutton" type="radio" v-model="tabEnableFlg" value="1" id="activate" name="radio_setting" />
                  <label class="radiobutton__label" for="activate">
                    ON
                  </label>
                </div>
                <div class="top-bar__radio">
                  <input class="radiobutton" type="radio" v-model="tabEnableFlg" value="0" id="deactivate" name="radio_setting" />
                  <label class="radiobutton__label" for="deactivate">
                    OFF
                  </label>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <!-- /入力 [タイトル、個別設定対象日付、設定有効化] -->

        <!-- 設定のコピー、エクスポート -->
        <div class="top-bar --secondary">
          <div class="top-bar__wrap">

            <!-- 対象の選択（セレクトボックス） -->
            <div class="top-bar__inner">
              <div class="top-bar__id">コピー元：</div>
              <div class="top-bar__item top-bar__item--select">
                <select
                  name=""
                  id=""
                  v-model="copyTabId"
                >
                  <option v-for="(d, key) in filterCopyTabList(tabList)" v-bind:key="key" :value="d.tab_id">{{ d.label }}</option>
                </select>
              </div>
            </div>

            <!-- ボタン [コピーする] -->
            <button class="button --small" @click="btnClickCopy">コピーする</button>
          </div>

          <!-- ボタン [エクスポートする] -->
          <button class="button --medium" @click="btnClickExport">エクスポートする</button>
        </div>
        <!-- /設定のコピー、エクスポート -->

        <!-- タブ毎の時間設定 -->
        <div>
          <li class="content">
            <section v-for="(category, key) in categoryList" v-bind:key="key" class="section">
              <h2 class="section__title">{{ category.label }}</h2>
              <div>
                <div class="bar">
                  <div class="bar__text"></div>
                  <div class="bar__main">
                    <span class="bar__item bar__head">調整中設定</span
                    ><span class="bar__item bar__head">開始時間</span
                    ><span class="bar__item bar__head">終了時間</span
                    >
                  </div>
                </div>
                <div
                  v-for="(data, index) in filterList(data_list, category.cid)"
                  :key="index"
                  class="data-bar"
                >
                  <div class="bar">
                    <div class="bar__text">
                      <span class="bar__id">{{ data.camera_no }}</span>
                      <span class="bar__name">{{ data.store_name }}</span>
                    </div>
                    <div class="bar__main">
                      <!-- 調整中設定 -->
                      <div class="bar__item">
                        <div class="bar__item">
                          <label class="button__toggle">
                            <input type="checkbox" v-model="data.adjust_flg" />
                          </label>
                        </div>
                      </div>
                      <!-- 開始時刻 -->
                      <input
                        type="time"
                        class="bar__item js-input"
                        value="data"
                        required="required"
                        v-model="data.open_time"
                      />
                      <!-- 終了時刻 -->
                      <input
                        type="time"
                        class="bar__item js-input"
                        value="data"
                        required="required"
                        v-model="data.close_time"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </li>
        </div>

      </ul>
      <button class="button --center" @click="btnClickUpdate">更新する</button>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {ja} from 'vuejs-datepicker/dist/locale'

export default {
  name: "Admin",
  components: {
    Datepicker
  },
  data() {
    return {
      isInit: false,                      // 初期化完了フラグ
      env: process.env.VUE_APP_NODE_ENV,  // 環境情報
      DatePickerFormat: 'yyyy/MM/dd',
      ja:ja,
      changeEventHandlers: [],            // バリデーションエラー情報リスト
      // 強制営業時間設定関連
      openTimeFlg: false,           // 強制営業時間設定 [0:無効, 1:有効]
      forceOpenType: 'force_open',  // 強制営業時間タイプ [force_open:強制全営業時間内, force_close:強制全営業時間外]
      // タブ関連
      data_list: [],                // 営業時間編集リスト
      copyTabList: [],              // 設定情報コピー用のリスト
      currentTabId : 1,             // 現在の選択タブ [初期値：1（デフォルト設定）]
      copyTabId : 1,                // コピー元のタブ [初期値：1（デフォルト設定）]
      tabEnableFlg: 0,          // 設定有効化 [0:無効, 1:有効]
      tabTitle: null,               // タイトル [例：個別設定1]
      tabCustomizeDate: null,       // 個別設定対象日付 [例：2024/10/01]
      tabList : [                   // 各タブの情報
        {tab_id:1, label:'デフォルト設定', data:null, is_regist:true},
        {tab_id:2, label:'--', data:null, is_regist:false},
        {tab_id:3, label:'--', data:null, is_regist:false},
        {tab_id:4, label:'--', data:null, is_regist:false},
        {tab_id:5, label:'--', data:null, is_regist:false},
        {tab_id:6, label:'--', data:null, is_regist:false},
        {tab_id:7, label:'--', data:null, is_regist:false},
      ],
      categoryList : [              // 営業時間カテゴリ情報
        {cid:1, label:'飲食店舗'},
        {cid:2, label:'馬券発売所'},
        {cid:3, label:'静止画'},
        {cid:4, label:'女性用トイレ'},
        {cid:5, label:'駐車場（手動混雑）'},
        {cid:6, label:'ショップ（手動混雑）'},
      ],
    };
  },
  created() {
    // 設定データ取得（強制営業時間設定）
    this.fetchGetForceOpenTime();
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.handlePopstate);
    // バリデーションエラーをクリア
    this.clearValidationError();
  },
  mounted() {
    window.addEventListener("popstate", this.handlePopstate);
  },
  watch: {
    currentTabId: function(tab_id, old_tab_id) {
      // タブ設定を変更
      this.changeOpenTimeData(tab_id, old_tab_id);
    },
  },
  methods: {
    // タブ切り替え
    handlePopstate() {
      this.changeTab();
    },

    // タブ切り替え時コンテンツの表示切り替え
    changeTab: function (tab_id) {
      if (tab_id) {
        if (tab_id !== this.currentTabId) {
          this.currentTabId = tab_id;
        }
      }
    },

    // 営業時間設定のトグルオンオフ ※現在は未使用
    handleOpenTimeFlgChange() {
      /*
      if (this.openTimeFlg) {
        // FIXME 営業時間設定が有効化された場合の処理
      } else {
        // FIXME 営業時間設定が無効化された場合の処理
      }
      */
    },

    // タブ情報をコピー
    btnClickCopy() {
      const alert = window.confirm("コピーを実行してもよろしいでしょうか？");
      if (!alert) { return; }

      // コピー元のタブ情報を取得
      const copy_tab_data = this.getTabDataByTabId(this.copyTabId);
      const copy_data = this.copyObject(copy_tab_data.data);
      // タブ情報を更新
      const tab_data = this.getTabDataByTabId(this.currentTabId);
      tab_data.data = copy_data;
      // タブ設定を変更
      this.changeOpenTimeData(this.currentTabId);
    },

    // エクスポート（csv出力）
    btnClickExport() {
      const alert = window.confirm("エクスポートを実行してもよろしいでしょうか？");
      if (!alert) { return; }

      // 保存用の営業時間設定を取得
      const save_data = this.getSaveData(this.currentTabId, true);

      // データを生成
      const title = save_data.tab_id == 1 ? this.tabList[0].label : save_data.title;
      const data_list = [
        ['タブID','タイトル', '個別設定対象日付', '設定有効化'],
        [save_data.tab_id, title, save_data.customize_date, save_data.enable_flg],
        ['カテゴリ','カメラNO','カメラ名', '調整中設定', '開始時間', '終了時間']
      ]
      // 営業時間を追加
      for(let key in this.categoryList){
        let cid =  this.categoryList[key].cid;
        let list = this.filterList(save_data.data, cid);
        for(let i in list){
          let d = list[i];
          data_list.push([
            d.category,   // カテゴリ
            d.camera_no,  // カメラNO
            d.store_name, // カメラ名
            d.adjust_flg, // 調整中設定
            d.open_time,  // 開始時刻
            d.close_time  // 終了時刻
          ]);
        }
      }
      // CSV出力用のデータを生成
      let body = '';
      for(let i in data_list){
        body += '"' + data_list[i].join('","') + '"' + "\n";
      }
      // ファイル生成
      const file_name = `admin_setting_${save_data.tab_id}_${this.getTimestamp()}`;
      const bom  = new Uint8Array([0xEF, 0xBB, 0xBF]);
      const blob = new Blob([bom, body], {type:"text/csv"});
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = file_name;
      link.click();
    },
    // タイムスタンプを取得
    getTimestamp: function () {
      const date = new Date();
      return date.getFullYear()
        + ('0' + (date.getMonth() + 1)).slice(-2)
        + ('0' + date.getDate()).slice(-2)
        + ('0' + date.getHours()).slice(-2)
        + ('0' + date.getMinutes()).slice(-2)
        + ('0' + date.getSeconds()).slice(-2);
    },

    /**
     * JSONデータ取得
     */
    // 設定データ取得（強制営業時間設定）
    async fetchGetForceOpenTime() {
      // jsonファイルパス
      const base = process.env.VUE_APP_2024_2nd_S3_BASE_URL;
      const file_path = `${base}${this.env}/force_open_time/force_open_time.json`;

      await this.$axios
        .get(`${file_path}?` + new Date().getTime())
        .then((response) => {
          // console.log('force_open_time.json success : ', response.data);
          // 強制営業時間設定 初期化
          if(response.data) {
            this.initForceOpenTimeData(response.data)
          }
          // 設定データ取得（営業時間設定）
          this.fetchGetOpenTime(0);
        })
        .catch((err) => {
          console.error('force_open_time.json error : ', err);
          // 設定データ取得（営業時間設定）
          this.fetchGetOpenTime(0);
        });
    },

    // 設定データ取得（営業時間設定）
    async fetchGetOpenTime(index) {
      if(this.tabList.length <= index) {
        this.initCompleteOpenTimeData();
        return;
      }
      const tab_data = this.tabList[index];
      // jsonファイルパス
      const base = process.env.VUE_APP_2024_2nd_S3_BASE_URL;
      const file_path = `${base}${this.env}/open_time/open_time_${tab_data.tab_id}.json`;
      // APIリクエスト
      await this.$axios
        .get(`${file_path}?` + new Date().getTime())
        .then((response) => {
          // console.log(`open_time_${tab_data.tab_id}.json success : `, response.data);
          if(response.data) {
            // 設定データを初期化
            this.initOpenTimeData(index, response.data);
          }
          // 次のタブデータを取得
          this.fetchGetOpenTime(++index);
        })
        .catch((err) => {
          console.error(`open_time_${tab_data.tab_id}.json error : `, err);
          // 次のタブデータを取得
          this.fetchGetOpenTime(++index);
        });
    },

    /**
     * JSONデータ初期化
     */
    // 強制営業時間設定
    initForceOpenTimeData: function (data) {
      this.openTimeFlg    = data.open_time_flg == 1 ? true : false; // 営業時間設定 例：0 or 1
      this.forceOpenType  = data.force_open_type; // 強制営業時間タイプ [force_open:強制全営業時間内, force_close:強制全営業時間外]
    },

    // 営業時間設定（初期化）
    initOpenTimeData: function (index, data) {
      const tab_data = this.tabList[index];
      // 上部タブの表示（ラベル）を初期化
      if(index > 0 && data.title != null){
        tab_data.label = data.title;
        tab_data.is_regist = true;  // jsonデータ登録済み
      }
      // jsonファイル情報を保存
      tab_data.data = data;
      // リストを更新
      this.$set(this.tabList, index, tab_data);

      // データを画面に反映
      if(tab_data.tab_id == this.currentTabId) {
        this.changeOpenTimeData(tab_data.tab_id, null);
      }
    },

    // 営業時間設定（完了時に実行）
    initCompleteOpenTimeData: function () {
      // 初期化データを生成
      const default_tab_data = this.copyObject(this.tabList[0].data);
      for(let i in default_tab_data.data) {
        let d = default_tab_data.data[i];
        d.adjust_flg = 0;
        d.open_time = 0;
        d.close_time = 0;
      }
      for(let i=1; this.tabList.length > i; i++) {
        let tab_data = this.tabList[i];
        // jsonデータが存在し無し場合は初期データを設定
        if(tab_data.data == null) {
          let tmp_data = this.copyObject(default_tab_data);
          tmp_data.tab_id = tab_data.tab_id;  // タブID
          tmp_data.enable_flg = 0;        // 設定有効化
          tmp_data.title = null;          // タイトル
          tmp_data.customize_date = null; // 個別設定対象日付
          tab_data.data = tmp_data;
        }
        // jsonデータ登録済み
        else {
          // tab_data.is_regist = true;
        }
      }
      // タブ設定を更新
      this.updateCopyTabList();
      // 初期化完了
      this.isInit = true;
    },

    /**
     * タブ変更
     */
    // タブ設定を変更
    changeOpenTimeData: function (tab_id, old_tab_id) {
      const tab_data = this.getTabDataByTabId(tab_id);
      //現在の設定を保存
      if(old_tab_id != null){
        const old_tab_data = this.getTabDataByTabId(old_tab_id);
        old_tab_data.enable_flg = this.tabEnableFlg;
        old_tab_data.title = this.tabTitle;
        old_tab_data.customize_date = this.tabCustomizeDate;
      }
      this.tabEnableFlg = tab_data.enable_flg;          // 設定有効化 [0:無効, 1:有効]
      this.tabTitle = tab_data.title;                   // タイトル [例：個別設定1]
      this.tabCustomizeDate = tab_data.customize_date;  // 個別設定対象日付 [例：2024/10/01]
      // デフォルト設定
      if(tab_data.tab_id == 1){
        this.tabEnableFlg = 1;
        this.tabTitle = null;
        this.tabCustomizeDate = null;
      }
      // 営業時間データ
      const time_data = tab_data ? tab_data.data : [];
      this.data_list = [];
      this.data_list = time_data;

      if(this.isInit){
        // タブ設定を更新
        this.updateCopyTabList();
        // バリデーションエラーをクリア
        this.clearValidationError();
      }
    },

    // タブ設定を更新
    updateCopyTabList: function () {
      if(this.currentTabId == 1){
        const list = this.filterCopyTabList(this.tabList);
        this.copyTabId = list.length > 0 ? list[0].tab_id : 1;
      }else{
        this.copyTabId = 1
      }
    },

    // tab_idからタブのデータを取得
    getTabDataByTabId: function (tab_id) {
      const list = this.tabList.filter((item) => {
        return item.tab_id == tab_id;
      });
      return list.length > 0 && list[0].data ? list[0].data : null;
    },

    // 営業時間のカテゴリ毎のリストを取得
    filterList: function (list, cid) {
      return list.filter((item) => {
        return item.cid == cid;
      });
    },

    // コピー元のタブリストを取得
    filterCopyTabList: function (list) {
      return list.filter((item) => {
        return item.is_regist && item.label != null && item.tab_id != this.currentTabId;
      });
    },

    // オブジェクトのコピー
    copyObject: function (obj) {
      return JSON.parse(JSON.stringify(obj))
    },

    // 日付フォーマットを変更（2024-08-31T15:00:00.000Z → 2024/08/31）
    formatDate: function (date) {
      if(date != null && date != ''){
        date = new Date(date);
        date = date.getFullYear()
          + '/' + ('0' + (date.getMonth() + 1)).slice(-2)
          + '/' + ('0' + date.getDate()).slice(-2);
      }
      return date;
    },

    /**
     * APIリクエスト
     */
    // 強制営業時間設定
    btnClickForceUpdate() {
      // アラート表示
      const alert = window.confirm('営業時間設定を更新してもよろしいですか？');

      if (alert) {
        // APIリクエスト
        let base = process.env.VUE_APP_2024_2nd_API_BASE_URL;
        this.$axios
          .post(
            `${base}change-force-open-time`,
            {
              open_time_flg   : this.openTimeFlg ? 1 : 0, // 営業時間設定
              force_open_type : this.forceOpenType,       // 強制営業時間タイプ
            },
            {
              withCredentials: false,
            }
          )
          .then(() => {
            this.$router.go({
              path: this.$router.currentRoute.path,
              force: true,
            });
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },

    // タブ毎の情報
    btnClickUpdate() {
      const is_error = this.validation();
      // エラー表示
      if (is_error) {
        window.confirm('登録に失敗しました。入力内容を確認してください');
      } else {
        // アラート表示
        const tab_data  = this.getTabDataByTabId(this.currentTabId);  // タブ情報
        const label     = this.currentTabId == 1 ? this.tabList[0].label : this.tabTitle;
        const status    = tab_data.is_regist ? '更新' : '登録';
        const message   = `${label}を${status}してもよろしいですか？`;
        const alert     = window.confirm(message);

        if (alert) {
          // 保存用の営業時間設定を取得
          const save_data = this.getSaveData(this.currentTabId);
          // APIリクエスト
          let base = process.env.VUE_APP_2024_2nd_API_BASE_URL;
          this.$axios
            .post(
              `${base}change-open-time`,
              save_data,
              {
                withCredentials: false,
              }
            )
            .then(() => {
              this.$router.go({
                path: this.$router.currentRoute.path,
                force: true,
              });
            })
            .catch((err) => {
              console.error(err);
            });
        }
      }
    },

    // 保存用の営業時間設定を取得
    getSaveData(tab_id, is_csv) {
      const tab_data  = this.getTabDataByTabId(tab_id);  // タブ情報
      // 営業時間リストを生成
      const time_list = tab_data.data.map(d => {
        let res = {
          camera_no   : d.camera_no,          // カメラNO
          adjust_flg  : d.adjust_flg ? 1 : 0, // 調整中設定
          open_time   : d.open_time,          // 開始時刻
          close_time  : d.close_time          // 終了時刻
        };
        if(is_csv){
          res.cid         = d.cid;            // カテゴリID
          res.category    = d.category;       // カテゴリ
          res.store_name  = d.store_name;     // カメラ名
        }
        return res;
      });
      // 保存データ
      const result = {
        tab_id          : tab_id,                      // タブID
        enable_flg      : parseInt(this.tabEnableFlg),            // 設定有効化 [0:無効, 1:有効]
        title           : this.tabTitle,                          // タイトル [例：個別設定1]
        customize_date  : this.formatDate(this.tabCustomizeDate), // 個別設定対象日付 [例：2024/10/01]
        data            : time_list,                              // 営業時間リスト
      };
      // 別タブの場合は保存データから取得
      if(this.currentTabId != tab_id) {
        result.enable_flg     = tab_data.enable_flg;
        result.title          = tab_data.title;
        result.customize_date = tab_data.customize_date;
      }
      return result;
    },

    // バリデーション
    validation() {
      let is_error = false;
      this.clearValidationError();  // バリデーションエラーをクリア

      // エラー判定
      const inputs = document.querySelectorAll(".js-input");
      inputs.forEach((element) => {
        if (element.value === "") {
          // デフォルト設定のタイトルは対象外
          if(this.currentTabId == 1 && element.name == 'title') {
            return true;  // continue
          }
          is_error = true;
          element.classList.add("--error");
          // イベント登録
          let handl = function f() {
            this.removeEventListener("change", f);
            this.classList.remove("--error");
          };
          this.changeEventHandlers.push({
            type    : 'input',
            element : element,
            handl   : handl
          });
          element.addEventListener("change", handl);
        }
      });
      // エラー判定 [デートピッカー]
      // ※デフォルト設定の個別設定対象日付は対象外
      const customize_date = document.getElementsByName('customize_date');
      if(this.currentTabId != 1 && customize_date){
        const element = customize_date[0];
        const val = element.value;
        if (val == "") {
          is_error = true;
          element.style.border = '1px solid #ff0000';
          // イベント登録
          let handl = function f() {
            if(this.value != ''){
              this.removeEventListener("focusout", f);
              this.style.border = 'none';
            }
          };
          this.changeEventHandlers.push({
            type    : 'datepicker',
            element : element,
            handl   : handl
          });
          element.addEventListener("focusout", handl);
        }
      }
      return is_error;
    },

    // バリデーションエラーをクリア
    clearValidationError() {
      if(this.changeEventHandlers.length > 0){
        for(let i in this.changeEventHandlers){
          let d = this.changeEventHandlers[i];
          if(d.type == 'datepicker'){
            d.element.style.border = 'none';
            d.element.removeEventListener("focusout", d.handl);
          }else{
            d.element.classList.remove("--error");
            d.element.removeEventListener("change", d.handl);
          }
        }
        this.changeEventHandlers = [];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  background: #268300;
  min-width: 1000px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  &__logo {
    margin-right: 40px;
  }
}
.top-bar {
  background: #004c3e;
  min-width: 1000px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  select,
  input {
    color: $c-black;
    font-weight: normal;
  }
  &.--primary {
    height: 60px;
    background: #ccebb5;
    color: $c-black;
    display: grid;
    grid-template-columns: 182px 234px;
  }
  &.--secondary {
    height: fit-content;
    background: transparent;
    color: $c-black;
    display: flex;
    align-items: flex-end;
    gap: 24px;
    margin-top: 24px;
    justify-content: flex-end;
    .top-bar__id {
      margin-bottom: 4px;
    }
  }
  .top-bar__item {
    width: 234px;
    height: 40px;
    &--select {
      position: relative;
      &::after {
        position: absolute;
        content: "";
        right: 9px;
        top: 15px;
        border-bottom: solid 1px #343a40;
        border-right: solid 1px #343a40;
        width: 7px;
        height: 7px;
        transform: rotate(45deg);
      }
    }
  }
  &__id {
    margin-right: 20px;
  }
  &__radio {
    display: flex;
    align-items: center;
    margin-left: 4px;
    & + & {
      margin-left: 48px;
    }
  }
  &__radio-wrap {
    display: flex;
    row-gap: 48px;
  }
  &__wrap {
    display: flex;
    align-items: flex-end;
    gap: 12px;
  }
  &__inner {
    display: grid;
    gap: 4px;
  }
  &__datepicker {
    &::v-deep header {
      width: auto;
      height: auto;
      background: unset;
    }
    &::v-deep input {
      width: 234px;
      height: 40px;
      border: 1px solid #d4d4d4;
      padding-left: 8px;
      background: #fff;
    }
  }
}

select,
input {
  border: 1px solid #d4d4d4;
  padding-left: 8px;
  background: #fff;
}

select {
  appearance: none;
  width: 100%;
  height: 100%;
  &:disabled {
    border: #B0B0B0;
    background: #B0B0B0;
    color: rgb(0 0 0 / .35);
  }
}
label {
  margin-bottom: 0;
}

input {
  &.--error {
    border: 1px solid #ff0000;
  }
}

.form {
  width: 1000px;
  margin: 30px auto 80px;
}

.section {
  margin-bottom: 60px;
  &__title {
    background: #268300;
    height: 48px;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 24px;
    padding-left: 20px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  &:first-of-type {
    margin-top: 30px;
  }
}

.bar {
  padding: 10px 20px;
  display: flex;

  &__text {
    display: flex;
    align-items: center;
    width: 300px;
    // min-width: 300px;
    margin-right: 10px;
    font-weight: bold;
  }

  &__main {
    flex: 1;
    display: flex;
    align-items: center;
  }

  &__item {
    width: 100px;
    height: 40px;
    &--select {
      position: relative;
      &::after {
        position: absolute;
        content: "";
        right: 9px;
        top: 15px;
        border-bottom: solid 1px #343a40;
        border-right: solid 1px #343a40;
        width: 7px;
        height: 7px;
        transform: rotate(45deg);
      }
    }
    &:not(:last-child) {
      margin-right: 10px;
    }
    &:nth-child(1),
    &:nth-child(2) {
      width: 120px;
    }
  }

  &__head {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d4d4d4;
    font-weight: bold;
  }

  &__id {
    min-width: 85px;
    margin-right: 10px;
  }

  &__name {
    flex: 1;
  }
}

.button {
  display: block;
  text-align: center;
  width: 400px;
  height: 80px;
  background: #268300;
  color: #fff;
  font-size: 24px;
  border: 0;
  border-radius: 100px;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
    background: #268300;
  }
  &.--small {
    width: 140px;
    height: 40px;
    font-size: 16px;
  }
  &.--medium {
    width: 200px;
    height: 40px;
    font-size: 16px;
  }
  &.--border {
    border: 2px solid #fff;
    margin-left: 20px;
  }
  &.--center {
    margin: auto;
  }
}

.data-bar {
  &:nth-child(even) {
    background: #efefef;
  }
}

.tabs {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto 20px;
  max-width: $contentsWidth;

  @media screen and (max-width: $sp_point) {
    justify-content: space-between;
  }

  .tab {
    padding: 4px;
    width: 20%;
    border-radius: 4px;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 600;
    color: $c-white;
    padding-bottom: 2px;
    cursor: pointer;
    background-color: #d4d4d4;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:last-child) {
      margin-right: 10px;
    }

    &.active {
      background-color: #6cb901;
      cursor: default;
    }
  }
}

.button__toggle {
  display: inline-block;
  position: relative;
  width: 82px;
  height: 40px;
  border-radius: 50px;
  background-color: #888888;
  box-sizing: content-box;
  cursor: pointer;
  transition: border-color .4s;
  font-size: 12px;
  &:has(:checked) {
    background-color: $c-primary;
  }
  &.--primary {
    font-weight: normal;
    &::before {
      color: $c-black;
      content: '無効';
    }
    &:has(:checked)::before {
      content: '有効';
    }
  }
  &::before {
    position: absolute;
    top: 50%;
    right: 9px;
    transform: translateY(-50%);
    content: 'OFF';
    transition: left 1s;
  }
  &::after {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: $c-white;
    content: '';
    transition: left .4s;
  }
  &:has(:checked)::after {
    left: 44px;
    background-color: $c-white;
    content: '';
  }
  &:has(:checked)::before {
    left: 10px;
    color: $c-white;
    content: 'ON';
  }
  &:has(:focus-visible){
    text-decoration: underline;
    outline: auto oklch(60% 0.4 240deg);
    outline-offset: 4px;
  }
  input {
    opacity: 0;
  }
  & + .top-bar__item {
    margin-left: 14px;
  }
}
.radiobutton {
  accent-color: $c-primary;
  transform: scale(1.5);
  &__label {
    margin-left: 12px;
    &:first-child {
      margin-right: 48px;
    }
  }
}
.setting-list {
  display: grid;
  gap: 8px;
}
</style>
