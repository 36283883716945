<template>
  <div class="button-icon">
    <a
      v-if="external"
      :href="to"
      class="button-icon__body"
      :class="`--${type}`"
      target="_blank"
    >
      <div>
        <div class="button-icon__main">
          <img
            class="button-icon__img"
            :src="require(`@/assets/img/sp/icon/icon_${icon.name}.png`)"
            :srcset="`${require(`@/assets/img/sp/icon/icon_${icon.name}@2x.png`)} 2x`"
            :width="icon.width"
            :height="icon.height"
            alt=""
          />
          <span class="button-icon__title">{{ text.title }}</span>
        </div>
        <span v-if="text.sub" class="button-icon__sub">{{ text.sub }}</span>
      </div>
      <span class="button-icon__arrow"></span>
    </a>
    <router-link v-else class="button-icon__body" :class="`--${type}`" :to="to">
      <div>
        <div class="button-icon__main">
          <img
            class="button-icon__img"
            :src="require(`@/assets/img/sp/icon/icon_${icon.name}.png`)"
            :srcset="`${require(`@/assets/img/sp/icon/icon_${icon.name}@2x.png`)} 2x`"
            :width="icon.width"
            :height="icon.height"
            alt=""
          />
          <span class="button-icon__title">{{ text.title }}</span>
        </div>
        <span v-if="text.sub" class="button-icon__sub">{{ text.sub }}</span>
      </div>
      <span class="button-icon__arrow"></span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "ButtonIcon",
  props: ["external", "to", "type", "icon", "text"],
};
</script>
