<template>
  <div class="parking__map" :style="mapStyle">
    <div class="parking__map-wrap" :style="wrapStyle">
      <img
        src="@/assets/img/sp/parking/map/map_parking.png"
        srcset="@/assets/img/sp/parking/map/map_parking@2x.png 2x"
        alt=""
        class="parking__map-img"
      />
      <ul v-if="statusList" class="bubble-parking">
        <li
          v-for="(item, index) in statusList"
          class="bubble-parking__item"
          :class="`--${item.no}`"
          :key="index"
        >
          <img
            v-if="item.info.img"
            v-lazy="
              require(`@/assets/img/sp/status/parking/${item.info.img}-m.png`)
            "
            :srcset="`${require(`@/assets/img/sp/status/parking/${item.info.img}-m@2x.png`)} 2x`"
            :alt="item.info.alt"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapParking",
  props: ["statusList"],
  data() {
    return {
      windowWidth: Number(window.innerWidth) - 20, // 20：マップ左右のマージン
      mapWidth: 355,
      mapHeight: 300,
      scale: this.windowWidth / this.mapWidth,
    };
  },
  methods: {
    setScale() {
      this.windowWidth =
        window.innerWidth <= 1080 ? Number(window.innerWidth) - 20 : 1080 - 20;
      this.scale = this.windowWidth / this.mapWidth;
    },
  },
  computed: {
    wrapStyle() {
      return { transform: `scale(${this.scale})` };
    },
    mapStyle() {
      return { minHeight: `${this.mapHeight * this.scale}px` };
    },
  },
  mounted() {
    window.addEventListener("resize", this.setScale);
    this.setScale();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setScale);
  },
};
</script>
