<template>
  <div class="tabPage tabPage1">
    <div class="content__bar"></div>
    <TmpMessage v-if="tmp" />
    <ul class="childtabs" id="restaurant" :class="{ fixed: tabTtlActive }">
      <li class="tab">
        <a href="#forth">4階</a>
      </li>
      <li class="tab">
        <a href="#third">3階</a>
      </li>
      <li class="tab">
        <a href="#second">2階</a>
      </li>
      <li class="tab">
        <a href="#first">1階</a>
      </li>
    </ul>
    <div v-for="(value, key) in floorData" :key="key">
      <span
        class="floor-container"
        :id="value.floor"
        :class="{ fixed: value.fixed ? tabTtlActive : null }"
      ></span>
      <div class="content__label">
        <img
          v-lazy="
            require(`@/assets/img/sp/floor/subtitle_floor${value.id}.png`)
          "
          :srcset="`${require(`@/assets/img/sp/floor/subtitle_floor${value.id}@2x.png`)} 2x`"
          width="37"
          height="33"
          :class="`subtitle_img_${value.id}`"
          :alt="`${value.id}階`"
        />
      </div>
      <data-list
        :dataList="value.dataList"
        :statusList="value.statusListFloor"
        :className="`${classNameFloor}${value.id}`"
      />
    </div>
  </div>
</template>

<script>
import DataList from "../list/DataList.vue";
import TmpMessage from "../message/TmpMessage.vue";

export default {
  name: "TabFloor",
  components: {
    DataList,
    TmpMessage,
  },
  created() {
    this.testId = this.$router.currentRoute.params.test;
    this.getData(this.testId);
    this.checkMessage();
  },
  mounted() {
    window.addEventListener("scroll", this.scrollWindow);
    this.updateData();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollWindow);
  },
  data() {
    return {
      testId: null,
      floorData: [
        {
          id: 4,
          floor: "forth",
          fixed: true,
          dataList: {
            map: {
              image: {
                src: "floor/map/map-4-f",
                width: "355",
                height: "82",
                alt: "4階のマップ",
              },
            },
          },
          statusListFloor: {
            id: "floor",
            column: "column2",
            path: "floor/set",
            data: [
              {
                category: "floor",
                src: "set-74",
                width: "172",
                height: "108",
                alt: "59",
                class: "frame_center",
                info: { alt: "59", img: "status-1" },
              },
              {
                category: "floor",
                src: "set-401",
                width: "172",
                height: "108",
                alt: "15",
                class: "frame_center",
                info: { alt: "15", img: "status-1" },
              },
              {
                category: "floor",
                src: "set-402-p",
                width: "172",
                height: "108",
                alt: "14",
                class: "frame_center",
                info: { alt: "14", img: "status-1" },
              },
              {
                category: "floor",
                src: "set-402-b",
                width: "172",
                height: "108",
                alt: "13",
                class: "frame_center",
                info: { alt: "13", img: "status-1" },
              },
              {
                category: "floor",
                src: "set-403",
                width: "172",
                height: "108",
                alt: "12",
                class: "frame_center",
                info: { alt: "12", img: "status-1" },
              },
            ],
          },
        },
        {
          id: 3,
          floor: "third",
          fixed: false,
          dataList: {
            map: {
              image: {
                src: "floor/map/map-3-f",
                width: "355",
                height: "74",
                alt: "フジビュースタンド1階のマップ",
              },
            },
          },
          statusListFloor: {
            id: "floor",
            column: "column2",
            path: "floor/set",
            data: [
              {
                category: "floor",
                src: "set-73",
                width: "172",
                height: "108",
                alt: "60",
                class: "frame_center",
                info: { alt: "60", img: "status-1" },
              },
            ],
          },
        },
        {
          id: 2,
          floor: "second",
          fixed: false,
          dataList: {
            map: {
              image: {
                src: "floor/map/map-2-f",
                width: "355",
                height: "106",
                alt: "フジビュースタンド1階のマップ",
              },
            },
          },
          statusListFloor: {
            id: "floor",
            column: "column2",
            path: "floor/set",
            data: [
              {
                category: "floor",
                src: "set-72",
                width: "172",
                height: "108",
                alt: "63",
                class: "frame_center",
                info: { alt: "63", img: "status-1" },
              },
              {
                category: "floor",
                src: "set-201",
                width: "172",
                height: "108",
                alt: "33",
                class: "frame_center",
                info: { alt: "33", img: "status-1" },
              },
              {
                category: "floor",
                src: "set-202",
                width: "172",
                height: "108",
                alt: "34",
                class: "frame_center",
                info: { alt: "34", img: "status-1" },
              },
              {
                category: "floor",
                src: "set-203",
                width: "172",
                height: "108",
                alt: "32",
                class: "frame_center",
                info: { alt: "32", img: "status-1" },
              },
              {
                category: "floor",
                src: "set-204",
                width: "172",
                height: "108",
                alt: "37",
                class: "frame_center",
                info: { alt: "37", img: "status-1" },
              },
              {
                category: "floor",
                src: "set-205",
                width: "172",
                height: "108",
                alt: "31",
                class: "frame_center",
                info: { alt: "31", img: "status-1" },
              },
              {
                category: "floor",
                src: "set-206",
                width: "172",
                height: "108",
                alt: "36",
                class: "frame_center",
                info: { alt: "36", img: "status-1" },
              },
              {
                category: "floor",
                src: "set-207",
                width: "172",
                height: "108",
                alt: "30",
                class: "frame_center",
                info: { alt: "30", img: "status-1" },
              },
              {
                category: "floor",
                src: "set-208",
                width: "172",
                height: "108",
                alt: "35",
                class: "frame_center",
                info: { alt: "35", img: "status-1" },
              },
              {
                category: "floor",
                src: "set-209",
                width: "172",
                height: "108",
                alt: "29",
                class: "frame_center",
                info: { alt: "29", img: "status-1" },
              },
            ],
          },
        },
        {
          id: 1,
          floor: "first",
          fixed: false,
          dataList: {
            map: {
              image: {
                src: "floor/map/map-1-f",
                width: "355",
                height: "76",
                alt: "フジビュースタンド1階のマップ",
              },
            },
          },
          statusListFloor: {
            id: "floor",
            column: "column2",
            path: "floor/set",
            data: [
              {
                category: "floor",
                src: "set-71",
                width: "172",
                height: "108",
                alt: "67",
                class: "frame_center",
                info: { alt: "67", img: "status-1" },
              },
              {
                category: "floor",
                src: "set-104",
                width: "172",
                height: "108",
                alt: "50",
                class: "frame_center",
                info: { alt: "50", img: "status-1" },
              },
              {
                category: "floor",
                src: "set-106",
                width: "172",
                height: "108",
                alt: "49",
                class: "frame_center",
                info: { alt: "49", img: "status-1" },
              },
            ],
          },
        },
      ],
      tmp: false,
      tmpHeight: 0,
      tabTtlActive: false,
      classNameFloor: "floor",
      jsonList: [],
      env: process.env.VUE_APP_NODE_ENV,
      envBase: process.env.VUE_APP_2024_2nd_S3_BASE_URL,
      envBaseTmp: process.env.VUE_APP_TMP_S3_BASE_URL,
    };
  },
  methods: {
    async checkMessage() {
      await this.$axios
        .get(`${this.envBaseTmp}message.json?` + new Date().getTime())
        .then((response) => {
          this.tmp = response.data.message;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getData(id) {
      const jsonURL =
        id === "99"
          ? `https://stg.jra-keibajo-konzatsu.jp/status_axis_2.json?`
          : `${this.envBase}${this.env}/congestion/axis_2.json?`;
      await this.$axios
        .get(jsonURL + new Date().getTime())
        .then((response) => {
          this.jsonList = response.data;
          this.searchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchData() {
      for (let item of Object.entries(this.floorData || {})) {
        const listData = item[1].statusListFloor.data;
        for (const i of listData) {
          const index = this.jsonList.find(
            (element) => element.camera_no === i.alt
          );
          if (index !== undefined) {
            // console.log(index);
            const status = index.status;
            i.info.name = index.camera_no;
            const type = index.display_type;
            this.divideData(index, status, type, i);
          }
        }
      }
    },
    divideData(index, status, type, target) {
      if (type === 1) {
        target.info.name = index.camera_no;
        if (status === 2) {
          target.info.img = "status-5";
        } else if (status === 3) {
          target.info.img = "status-6";
        } else {
          this.setImg(index, target);
        }
      }
    },
    setImg(index, target) {
      const level = index.congestion_level;
      if (level) {
        target.info.img = `status-${level}`;
      }
    },
    updateData() {
      if (this.checkSessionStorageAvailable()) {
        if (sessionStorage.getItem("tabIntervalId")) {
          clearInterval(sessionStorage.getItem("tabIntervalId"));
        }
      }
      this.timeout = setInterval(() => {
        this.getData(this.testId);
        this.checkMessage();
      }, 20000);
      if (this.checkSessionStorageAvailable()) {
        sessionStorage.setItem("tabIntervalId", this.timeout);
      }
    },
    checkSessionStorageAvailable() {
      try {
        sessionStorage.setItem("tabDummy", 1);
        sessionStorage.removeItem("tabDummy");
        return true;
      } catch {
        return false;
      }
    },
    scrollWindow() {
      if (this.tmp) {
        this.tmpHeight =
          document.getElementById("tmp-message").offsetHeight + 40;
      }
      const tab = 134 + this.tmpHeight;
      this.scroll = window.pageYOffset;
      if (tab <= this.scroll) {
        this.tabTtlActive = true;
      } else {
        this.tabTtlActive = false;
      }
    },
  },
};
</script>
