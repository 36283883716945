<template>
  <div class="signage signage-2">
    <div class="img_container">
      <img
        key="restaurant5"
        src="@/assets/img/signage/img-paddock.png"
        width="1080"
        height="1920"
        class="slideshow"
        alt="パドック"
      />
      <img
        key="floor1"
        :src="
          require(`@/assets/img/signage/img-ticket-${currentFloor.ticket}.png`)
        "
        width="1080"
        height="1920"
        class="slideshow fadeout"
        alt="投票所"
      />
      <img
        key="other"
        :src="
          require(`@/assets/img/signage/img-turfy-umajo-${currentFloor.umajoTurfy}.png`)
        "
        width="1080"
        height="1920"
        class="slideshow fadeout"
        alt="ターフィーショップ／UMAJO"
      />
      <transition name="fade">
        <div v-if="index === 1" class="signage-polling">
          <signage-data-list :statusList="floorData" />
        </div>
      </transition>
      <transition name="fade">
        <div v-if="index === 0" class="signage-paddock">
          <signage-camera-list :dataList="cameraDataList" />
        </div>
      </transition>
      <transition name="fade">
        <div v-if="index === 2" class="signage-other">
          <signage-data-list :statusList="statusListUmajo" />
          <signage-data-list :statusList="statusListUmajoPlus" />
          <signage-data-list :statusList="statusListTurfy3" />
          <signage-data-list :statusList="statusListTurfy2" />
          <signage-data-list :statusList="statusListTurfyM60" />
        </div>
      </transition>
      <transition name="fade">
        <div v-if="currentId && index !== 0" class="qr-pos">
          <img
            :src="require(`@/assets/img/signage/qr/QR_${currentQr}.png`)"
            width="195"
            height="195"
            class="signage-qr"
            alt=""
          />
        </div>
      </transition>
      <transition name="fade">
        <div v-if="currentId && index === 0" class="qr-pos--paddock">
          <img
            :src="require(`@/assets/img/signage/qr/QR_${currentQr}.png`)"
            width="270"
            height="270"
            class="signage-qr"
            alt=""
          />
        </div>
      </transition>
      <transition name="fade">
        <div v-if="currentId && index === 0" class="qr-pos--paddock --t">
          <img
            :src="require(`@/assets/img/signage/qr/QR_${currentQr}_t.png`)"
            width="270"
            height="270"
            class="signage-qr"
            alt=""
          />
        </div>
      </transition>
      <transition name="fade">
        <div v-if="currentId && index === 0" class="qr-pos--paddock --p">
          <img
            :src="require(`@/assets/img/signage/qr/QR_${currentQr}_p.png`)"
            width="270"
            height="270"
            class="signage-qr"
            alt=""
          />
        </div>
      </transition>
      <transition name="fade">
        <span
          v-if="currentId && index !== 0"
          class="current-pos"
          :class="`current--${index}-${currentId}`"
        ></span>
      </transition>
    </div>
    <div v-if="tmp" class="content__text-container--tmp is-signage">
      <p class="content__text--tmp is-red">
        現在、アクセスが集中し、<br />ページを閲覧しづらい状態になっております。
      </p>
      <p class="content__text--tmp">
        日頃より、中山競馬場をご利用いただきましてありがとうございます。
      </p>
      <p class="content__text--tmp">
        現在、場内の電波状況により、ページ閲覧をしづらい状態になっております。
      </p>
      <p class="content__text--tmp">
        ご迷惑をおかけしまして誠に申し訳ございません。
      </p>
    </div>
  </div>
</template>
<script>
import SignageDataList from "../../components/list/SignageDataList.vue";
import SignageCameraList from "../../components/list/SignageCameraList.vue";

export default {
  components: {
    SignageDataList,
    SignageCameraList,
  },
  data() {
    return {
      testId: null,
      tmp: false,
      index: 0,
      currentId: null,
      currentQr: null,
      currentFloor: {
        ticket: 0,
        umajoTurfy: 0,
      },
      // sleep_ptn: [50, 3],
      sleep_ptn: [30, 15, 15],
      sleep_ptn_index: 0,
      jsonList: [],
      floorData: {
        data: [
          {
            category: "floor",
            img: "status-1",
            name: "set-74",
            width: "160",
            height: "42",
            alt: "59",
            class: "frame_center",
          },
          {
            category: "floor",
            img: "status-1",
            name: "set-401",
            width: "160",
            height: "42",
            alt: "15",
            class: "frame_center",
          },
          {
            category: "floor",
            img: "status-1",
            name: "set-402-p",
            width: "160",
            height: "42",
            alt: "14",
            class: "frame_center",
          },
          {
            category: "floor",
            img: "status-1",
            name: "set-402-b",
            width: "160",
            height: "42",
            alt: "13",
            class: "frame_center",
          },
          {
            category: "floor",
            img: "status-1",
            name: "set-403",
            width: "160",
            height: "42",
            alt: "12",
            class: "frame_center",
          },
          {
            category: "floor",
            img: "status-1",
            name: "set-73",
            width: "160",
            height: "42",
            alt: "60",
            class: "frame_center",
          },
          {
            category: "floor",
            img: "status-1",
            name: "set-72",
            width: "160",
            height: "42",
            alt: "63",
            class: "frame_center",
          },
          {
            category: "floor",
            img: "status-1",
            name: "set-201",
            width: "160",
            height: "42",
            alt: "33",
            class: "frame_center",
          },
          {
            category: "floor",
            img: "status-1",
            name: "set-202",
            width: "160",
            height: "42",
            alt: "34",
            class: "frame_center",
          },
          {
            category: "floor",
            img: "status-1",
            name: "set-203",
            width: "160",
            height: "42",
            alt: "32",
            class: "frame_center",
          },
          {
            category: "floor",
            img: "status-1",
            name: "set-204",
            width: "160",
            height: "42",
            alt: "37",
            class: "frame_center",
          },
          {
            category: "floor",
            img: "status-1",
            name: "set-205",
            width: "160",
            height: "42",
            alt: "31",
            class: "frame_center",
          },
          {
            category: "floor",
            img: "status-1",
            name: "set-206",
            width: "160",
            height: "42",
            alt: "36",
            class: "frame_center",
          },
          {
            category: "floor",
            img: "status-1",
            name: "set-207",
            width: "160",
            height: "42",
            alt: "30",
            class: "frame_center",
          },
          {
            category: "floor",
            img: "status-1",
            name: "set-208",
            width: "160",
            height: "42",
            alt: "35",
            class: "frame_center",
          },
          {
            category: "floor",
            img: "status-1",
            name: "set-209",
            width: "160",
            height: "42",
            alt: "29",
            class: "frame_center",
          },
          {
            category: "floor",
            img: "status-1",
            name: "set-71",
            width: "160",
            height: "42",
            alt: "67",
            class: "frame_center",
          },
          {
            category: "floor",
            img: "status-1",
            name: "set-104",
            width: "160",
            height: "42",
            alt: "50",
            class: "frame_center",
          },
          {
            category: "floor",
            img: "status-1",
            name: "set-106",
            width: "160",
            height: "42",
            alt: "49",
            class: "frame_center",
          },
        ],
      },
      cameraDataList: [
        {
          width: "1040",
          height: "586",
          class: "paddock",
          info: {
            alt: "11_パドック",
            camera_no: "11",
            img: "",
          },
        },
      ],
      statusListUmajo: {
        data: [
          {
            category: "manual",
            img: "status-1",
            alt: "UMAJO SPOT",
            name: "manual-1",
            extension: "png",
            width: "320",
            height: "40",
            class: "frame_center",
          },
        ],
      },
      statusListUmajoPlus: {
        // FIXME注意
        // path: "umajo",
        data: [
          {
            category: "manual",
            img: "status-1",
            alt: "04_UMAJO SPOT PLUS",
            name: "manual-2",
            extension: "png",
            width: "320",
            height: "40",
            class: "frame_center",
          },
        ],
      },
      statusListTurfy3: {
        data: [
          {
            category: "manual",
            img: "status-1",
            alt: "ターフィーショップ 3F",
            name: "manual-3",
            extension: "png",
            width: "320",
            height: "40",
            class: "frame_center",
          },
        ],
      },
      statusListTurfy2: {
        data: [
          {
            category: "manual",
            img: "status-1",
            alt: "ターフィーショップ 2F",
            name: "manual-4",
            extension: "png",
            width: "320",
            height: "40",
            class: "frame_center",
          },
        ],
      },
      statusListTurfyM60: {
        data: [
          {
            category: "manual",
            img: "status-1",
            alt: "タ−フィ−ショップ メモリアル60スタンド",
            name: "manual-5",
            extension: "png",
            width: "320",
            height: "40",
            class: "frame_center",
          },
        ],
      },
      env: process.env.VUE_APP_NODE_ENV,
      envBase: process.env.VUE_APP_2024_2nd_S3_BASE_URL,
      envBaseTmp: process.env.VUE_APP_TMP_S3_BASE_URL,
    };
  },
  created() {
    this.testId = this.$router.currentRoute.params.test;
    this.getData(this.testId);
    this.getCameraData(this.cameraDataList);
    this.checkMessage();
    this.getManualChangeData(this.statusListUmajoPlus, 1);
    this.getManualChangeData(this.statusListUmajo, 2);
    this.getManualChangeData(this.statusListTurfy3, 3);
    this.getManualChangeData(this.statusListTurfy2, 4);
    this.getManualChangeData(this.statusListTurfyM60, 5);
  },
  mounted() {
    this.getPosition();
    const images = document.getElementsByClassName("slideshow");
    this.slideshow(images);
    this.updateData();
    this.timeout = setInterval(() => {
      this.getData(this.testId);
      this.getCameraData(this.cameraDataList);
      this.checkMessage();
      // console.log("20秒");
      this.getManualChangeData(this.statusListUmajoPlus, 1);
      this.getManualChangeData(this.statusListUmajo, 2);
      this.getManualChangeData(this.statusListTurfy3, 3);
      this.getManualChangeData(this.statusListTurfy2, 4);
      this.getManualChangeData(this.statusListTurfyM60, 5);
    }, 20000);
  },
  beforeDestroy() {
    clearInterval(this.timeout);
  },
  methods: {
    slideshow(images) {
      const current = images[this.index];
      const prev = images[this.index - 1]
        ? images[this.index - 1]
        : images[images.length - 1];
      current.classList.add("fadein");
      current.classList.remove("fadeout");
      prev.classList.remove("fadein");
      prev.classList.add("fadeout");
    },
    slideInterval() {
      const images = document.getElementsByClassName("slideshow");
      this.index = this.index < images.length - 1 ? this.index + 1 : 0;
      this.slideshow(images);
    },
    getPosition() {
      this.currentId = this.$router.currentRoute.params.id;
      switch (this.currentId) {
        case "1":
          this.currentQr = "fuji5F";
          this.currentFloor.umajoTurfy = 5;
          break;
        case "2":
          this.currentQr = "fuji3F";
          this.currentFloor.ticket = 3;
          this.currentFloor.umajoTurfy = 3;
          break;
        case "3":
          this.currentQr = "fuji2F_west";
          this.currentFloor.ticket = 2;
          this.currentFloor.umajoTurfy = 2;
          break;
        case "4":
          this.currentQr = "fuji2F_info";
          this.currentFloor.ticket = 2;
          this.currentFloor.umajoTurfy = 2;
          break;
        case "5":
          this.currentQr = "fuji1F";
          this.currentFloor.ticket = 1;
          break;
        case "6":
          this.currentQr = "memorial2F";
          this.currentFloor.ticket = 2;
          this.currentFloor.umajoTurfy = 2;
          break;
        default:
          this.currentQr = null;
          break;
      }
      const currentList = ["1", "2", "3", "4", "5", "6"];
      if (!currentList.includes(this.currentId)) {
        this.currentId = null;
      }
    },
    async checkMessage() {
      await this.$axios
        .get(`${this.envBaseTmp}message.json?` + new Date().getTime())
        .then((response) => {
          this.tmp = response.data.message;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getManualChangeData(list, shopId) {
      await this.$axios
        .get(
          `${this.envBase}${this.env}/manual/shop_id_${shopId}.json?` +
            new Date().getTime()
        )
        .then((response) => {
          this.setImgManual(response.data[0].congestion_status, list.data[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getData(id) {
      const jsonURL =
        id === "99"
          ? `https://stg.jra-keibajo-konzatsu.jp/status_axis_2.json?`
          : `${this.envBase}${this.env}/congestion/axis_2.json?`;
      await this.$axios
        .get(jsonURL + new Date().getTime())
        .then((response) => {
          this.jsonList = response.data;
          this.searchData(this.jsonList);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getCameraData(list) {
      await this.$axios
        .get(
          `${this.envBase}${this.env}/camera_images.json?` +
            new Date().getTime()
        )
        .then((response) => {
          this.setCameraImg(response.data, list);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchData(jsonData) {
      for (let item of Object.entries(this.floorData || {})) {
        const listData = item[1];
        for (const i of listData) {
          this.divideData(i, jsonData);
        }
      }
    },
    divideData(i, jsonData) {
      let index = jsonData.find((element) => element.camera_no === i.alt);
      if (index !== undefined) {
        const status = index.status;
        if (status === 2) {
          i.img = "status-5";
        } else if (status === 3) {
          i.img = "status-6";
        } else {
          this.setImg(index, i);
        }
      }
    },
    setImg(index, i) {
      const level = index.congestion_level;
      if (level) {
        i.img = `status-${level}`;
      }
    },
    setImgManual(level, i) {
      if (level || level === 0) {
        i.img = `status-${level}`;
      }
    },
    setCameraImg(jsonData, listData) {
      for (const i in listData) {
        const target = listData[i].info;
        const index = jsonData.find(
          (element) => element.camera_no === target.camera_no
        );
        target.img = index.image_url + "?" + new Date().getTime();
      }
    },
    updateData() {
      // console.log(
      //   this.sleep_ptn[this.sleep_ptn_index] +
      //     "s" +
      //     "some_func() " +
      //     new Date() +
      //     " next: " +
      //     this.sleep_ptn[this.sleep_ptn_index] +
      //     "s"
      // );
      this.timeoutFunc = setTimeout(
        this.updateData,
        this.sleep_ptn[this.sleep_ptn_index] * 1000
      );
      this.sleep_ptn_index = (this.sleep_ptn_index + 1) % this.sleep_ptn.length;
      this.slideInterval();
    },
  },
};
</script>

<style lang="scss" scoped></style>
