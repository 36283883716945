<template>
  <div class="header__link" :class="{ '--show': show }">
    <ButtonIcon
      :external="left.external"
      :to="left.to"
      :type="left.type"
      :icon="left.icon"
      :text="left.text"
    />
    <ButtonIcon
      v-if="Object.keys(right).length !== 0"
      :external="right.external"
      :to="right.to"
      :type="right.type"
      :icon="right.icon"
      :text="right.text"
    />
  </div>
</template>

<script>
import ButtonIcon from "../button/ButtonIcon.vue";

export default {
  name: "LinkHeader",
  components: {
    ButtonIcon,
  },
  props: ["left", "right", "show"],
};
</script>
