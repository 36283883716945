<template>
  <div class="tabPage tabPage2">
    <div class="content__bar"></div>
    <TmpMessage v-if="tmp" />
    <ul class="childtabs" id="restaurant" :class="{ fixed: tabTtlActive }">
      <li class="tab">
        <a href="#second">2階</a>
      </li>
      <li class="tab">
        <a href="#first">1階</a>
      </li>
      <li class="tab">
        <a href="#b-first">B1階</a>
      </li>
      <li class="tab">
        <a href="#bajo">馬場内</a>
      </li>
    </ul>
    <div v-for="(value, key) in cameraData" :key="`camera__${key}`">
      <span
        class="floor-container"
        :id="value.floor"
        :class="{ fixed: value.fixed ? tabTtlActive : null }"
      ></span>
      <div class="content__label">
        <div v-if="value.text">{{ value.text }}</div>
        <img
          v-else
          v-lazy="
            require(`@/assets/img/sp/foodcourt/camera/subtitle_${value.id}.png`)
          "
          :srcset="`${require(`@/assets/img/sp/foodcourt/camera/subtitle_${value.id}@2x.png`)} 2x`"
          :width="value.width"
          :height="value.height"
          :class="`subtitle_img_${value.id}`"
          :alt="`${value.floorLabel}`"
        />
      </div>
      <data-list
        :dataList="value.dataList"
        :cameraData="value.cameraDataList"
        :className="`content__camera-list ${classNameFloorCamera}${value.id}`"
        :shopNameData="value.shopNameData"
      />
    </div>
  </div>
</template>

<script>
import DataList from "../list/DataList.vue";
import TmpMessage from "../message/TmpMessage.vue";

export default {
  name: "TabFoodCourt",
  components: {
    DataList,
    TmpMessage,
  },
  created() {
    // this.getCameraDataItem();
    this.checkMessage();
    this.getCameraData();
  },
  data() {
    return {
      tmp: false,
      tmpHeight: 0,
      tabTtlActive: false,
      classNameFloorCamera: "floor-camera",
      cameraData: [
        {
          id: "2_west",
          floor: "second",
          floorLabel: "ファストフ−ドプラザ（ウエスト）",
          fixed: true,
          width: "299",
          height: "37",
          shopNameData: {
            image: {
              src: "foodcourt/camera/txt-2-f",
              width: "362",
              height: "58",
              alt: "",
            },
          },
          dataList: {
            map: {
              image: {
                src: "foodcourt/map/map-2-f-food",
                width: "355",
                height: "134",
                alt: "ファストフードプラザ（ウエスト）2階のマップ",
              },
            },
          },
          cameraDataList: {
            type: "type2",
            title: {
              name: "txt_fastfood_2",
              extension: "png",
              width: "288",
              height: "28",
              alt: "ファストフードプラザ（ウエスト）2階",
            },
            name: "img-fastfood-2",
            extension: "jpg",
            width: "355",
            height: "200",
            info: {
              alt: "48_ファストフードウエスト_F2F",
              camera_no: "48",
              img: "",
              src: "img-ffp-west",
            },
          },
        },
        {
          id: "1_east",
          floor: "first",
          floorLabel: "ファストフ−ドプラザ（イ−スト）",
          fixed: false,
          width: "299",
          height: "37",
          shopNameData: {
            image: {
              src: "foodcourt/camera/txt-1-f",
              width: "346",
              height: "58",
              alt: "",
            },
          },
          dataList: {
            map: {
              image: {
                src: "foodcourt/map/map-1-f-food",
                width: "355",
                height: "130",
                alt: "ファストフ−ドプラザ（イ−スト）のマップ",
              },
            },
          },
          cameraDataList: {
            type: "type2",
            title: {
              name: "txt_fastfood_1",
              extension: "png",
              width: "284",
              height: "28",
              alt: "ファストフードプラザ（イースト）1階",
            },
            name: "img-fastfood-1",
            extension: "jpg",
            width: "355",
            height: "200",
            info: {
              alt: "56_ファストフードイースト_F1F",
              camera_no: "56",
              img: "",
              src: "img-ffp-west",
            },
          },
        },
        {
          id: "b1",
          floor: "b-first",
          floorLabel: "B1階ファストフ−ドプラザ",
          fixed: false,
          width: "224",
          height: "37",
          shopNameData: {
            image: {
              src: "foodcourt/camera/txt-b-1",
              width: "320",
              height: "37",
              alt: "",
            },
          },
          dataList: {
            map: {
              image: {
                src: "foodcourt/map/map-b-1",
                width: "355",
                height: "110",
                alt: "B1階ファストフ−ドプラザのマップ",
              },
            },
          },
          cameraDataList: {
            data: [
              {
                type: "type2",
                title: {
                  name: "txt_fastfood_1",
                  extension: "png",
                  width: "284",
                  height: "28",
                  alt: "ファストフ−ドプラザ（よし野側）",
                },
                name: "img-fastfood-2",
                extension: "jpg",
                width: "320",
                height: "240",
                info: {
                  alt: "68_フードコート_MB1F_南",
                  camera_no: "68",
                  img: "",
                  src: "img-ffp-1",
                },
              },
              {
                type: "type2",
                title: {
                  name: "txt_fastfood_2",
                  extension: "png",
                  width: "288",
                  height: "28",
                  alt: "ファストフ−ドプラザ（ハロンボウ側）",
                },
                name: "img-fastfood-1",
                extension: "jpg",
                width: "320",
                height: "240",
                info: {
                  alt: "69_フードコート_MB1F_北",
                  camera_no: "69",
                  img: "",
                  src: "img-ffp-2",
                },
              },
            ],
          },
        },
        {
          id: "b1",
          floor: "bajo",
          text: "馬場内 グルメエリア",
          floorLabel: "馬場内 グルメエリア",
          fixed: false,
          dataList: {
            map: {
              image: {
                src: "foodcourt/map/map-bajo",
                width: "355",
                height: "156",
                alt: "馬場内 グルメエリアのマップ",
              },
            },
          },
          cameraDataList: {
            type: "type2",
            title: {
              name: "txt_fastfood_1",
              extension: "png",
              width: "284",
              height: "28",
              alt: "馬場内 グルメエリア",
            },
            name: "img-fastfood-1",
            extension: "jpg",
            width: "355",
            height: "200",
            info: {
              alt: "57_馬場内",
              camera_no: "57",
              img: "",
              src: "img-bajo",
            },
          },
        },
      ],
      cameraList: [],
      env: process.env.VUE_APP_NODE_ENV,
      envBase: process.env.VUE_APP_2024_2nd_S3_BASE_URL,
      envBaseTmp: process.env.VUE_APP_TMP_S3_BASE_URL,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollWindow);
    this.updateData();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollWindow);
  },
  methods: {
    async checkMessage() {
      await this.$axios
        .get(`${this.envBaseTmp}message.json?` + new Date().getTime())
        .then((response) => {
          this.tmp = response.data.message;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCameraDataItem(list) {
      for (let item of Object.entries(this.cameraData)) {
        if (item[1].cameraDataList.data) {
          for (let i of Object.entries(item[1].cameraDataList.data)) {
            // console.log(i[1]);
            // this.getCameraData(i[1]);
            this.setCameraImg(list, i[1]);
          }
        } else {
          this.setCameraImg(list, item[1].cameraDataList);
        }
      }
    },
    async getCameraData() {
      await this.$axios
        .get(
          `${this.envBase}${this.env}/camera_images.json?` +
            new Date().getTime()
        )
        .then((response) => {
          // console.log(list.info);
          // this.setCameraImg(response.data, list);
          this.cameraList = response.data;
          this.getCameraDataItem(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setCameraImg(jsonData, listData) {
      const target = listData.info;
      const index = jsonData.find(
        (element) => element.camera_no === target.camera_no
      );
      if (index !== undefined) {
        target.img = index.sp_image_url + "?" + new Date().getTime();
      }
    },
    updateData() {
      if (this.checkSessionStorageAvailable()) {
        if (sessionStorage.getItem("tabIntervalId")) {
          clearInterval(sessionStorage.getItem("tabIntervalId"));
        }
      }
      this.timeout = setInterval(() => {
        this.getCameraData();
        this.checkMessage();
      }, 20000);
      // }, 1000);
      if (this.checkSessionStorageAvailable()) {
        sessionStorage.setItem("tabIntervalId", this.timeout);
      }
    },
    checkSessionStorageAvailable() {
      try {
        sessionStorage.setItem("tabDummy", 1);
        sessionStorage.removeItem("tabDummy");
        return true;
      } catch {
        return false;
      }
    },
    scrollWindow() {
      if (this.tmp) {
        this.tmpHeight =
          document.getElementById("tmp-message").offsetHeight + 40;
      }
      const tab = 134 + this.tmpHeight;
      this.scroll = window.pageYOffset;
      if (tab <= this.scroll) {
        this.tabTtlActive = true;
      } else {
        this.tabTtlActive = false;
      }
    },
  },
};
</script>
