<template>
  <div>
    <h1 class="title">
      <img
        class="title__logo"
        src="@/assets/img/admin/jra_logo.svg"
        alt="JRAロゴ"
      />混雑状況 閾値変更
    </h1>
    <div class="form">
      <!-- FIXME btnClickは仮で設定しており修正必要です -->
      <div class="button__wrap">
        <button class="button --medium" @click="btnClickExport">エクスポートする</button>
      </div>
      <ul class="tabs">
        <li
          @click="changeTab(1)"
          class="tab"
          :class="{ active: isActive === 1 }"
        >飲食店
        </li>
        <!-- <li
          @click="changeTab(2)"
          class="tab"
          :class="{ active: isActive === 2 }"
        >ターフィー/UMAJO
        </li> -->
        <li
          @click="changeTab(3)"
          class="tab"
          :class="{ active: isActive === 3 }"
        >女性用トイレ
        </li>
        <li
          @click="changeTab(4)"
          class="tab"
          :class="{ active: isActive === 4 }"
        >馬券発売所
        </li>
      </ul>

      <ul class="contents">
        <transition-group>
          <li class="content" v-show="isActive === 1" key="1">
            <section class="section">
              <h2 class="section__title">飲食店</h2>
              <div>
                <div class="bar">
                  <div class="bar__text"></div>
                  <div class="bar__main">
                    <span class="bar__item bar__head">段階表示</span
                    ><span class="bar__item bar__head">表示タイプ</span
                    ><span class="bar__item bar__head status--2">やや混雑</span
                    ><span class="bar__item bar__head status--3">混 雑</span
                    ><span class="bar__item bar__head status--4">非常に混雑</span>
                  </div>
                </div>
                <!-- FIXME filterList(data_list, 1)部分が修正必要です -->
                <div
                  v-for="(data, index) in filterList(data_list, 1)"
                  :key="index"
                  class="data-bar"
                >
                  <div class="bar">
                    <div class="bar__text">
                      <span class="bar__id">{{ data.name }}</span>
                      <span class="bar__name">{{ data.camera_name }}</span>
                    </div>
                    <div class="bar__main">
                      <div class="bar__item bar__item--select" v-if="data.level == 4">
                        <select
                          name=""
                          id=""
                          @change="changeSelectLevel(index, $event)"
                        >
                          <option value="4" selected>4段階</option>
                          <option value="3">3段階</option>
                        </select>
                      </div>
                      <div class="bar__item bar__item--select" v-else>
                        <select
                          name=""
                          id=""
                          @change="changeSelectLevel(index, $event)"
                        >
                          <option value="4">4段階</option>
                          <option value="3" selected>3段階</option>
                        </select>
                      </div>
                      <div
                        class="bar__item bar__item--select"
                        v-if="data.display_type == 1"
                      >
                        <select
                          name=""
                          id=""
                          @change="changeSelectDisplayType(index, $event)"
                        >
                          <option value="1" selected>混雑状況</option>
                          <option value="2">人数</option>
                        </select>
                      </div>
                      <div class="bar__item bar__item--select" v-else>
                        <select
                          name=""
                          id=""
                          @change="changeSelectDisplayType(index, $event)"
                        >
                          <option value="1">混雑状況</option>
                          <option value="2" selected>人数</option>
                        </select>
                      </div>
                      <input
                        type="number"
                        class="bar__item js-input"
                        :value="data.threshold_1"
                        required="required"
                        @input="data.threshold_1 = Number($event.target.value)"
                      />
                      <input
                        type="number"
                        class="bar__item js-input"
                        :value="data.threshold_2"
                        required="required"
                        @input="data.threshold_2 = Number($event.target.value)"
                      />
                      <input
                        v-if="data.level == 4"
                        type="number"
                        class="bar__item js-input"
                        :value="data.threshold_3"
                        required="required"
                        @input="data.threshold_3 = Number($event.target.value)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </li>
          <li class="content" v-show="isActive === 2" key="2">
            <section class="section">
              <h2 class="section__title">ターフィー/UMAJO</h2>
              <div>
                <div class="bar">
                  <div class="bar__text"></div>
                  <div class="bar__main">
                    <span class="bar__item bar__head">段階表示</span
                    ><span class="bar__item bar__head">表示タイプ</span
                    ><span class="bar__item bar__head status--2">やや混雑</span
                    ><span class="bar__item bar__head status--3">混 雑</span
                    ><span class="bar__item bar__head status--4">非常に混雑</span>
                  </div>
                </div>
                <!-- FIXME filterList(data_list, 1)部分が修正必要です -->
                <div
                  v-for="(data, index) in filterList(data_list, 1)"
                  :key="index"
                  class="data-bar"
                >
                  <div class="bar">
                    <div class="bar__text">
                      <span class="bar__id">{{ data.name }}</span>
                      <span class="bar__name">{{ data.camera_name }}</span>
                    </div>
                    <div class="bar__main">
                      <div class="bar__item bar__item--select" v-if="data.level == 4">
                        <select
                          name=""
                          id=""
                          @change="changeSelectLevel(index, $event)"
                        >
                          <option value="4" selected>4段階</option>
                          <option value="3">3段階</option>
                        </select>
                      </div>
                      <div class="bar__item bar__item--select" v-else>
                        <select
                          name=""
                          id=""
                          @change="changeSelectLevel(index, $event)"
                        >
                          <option value="4">4段階</option>
                          <option value="3" selected>3段階</option>
                        </select>
                      </div>
                      <div
                        class="bar__item bar__item--select"
                        v-if="data.display_type == 1"
                      >
                        <select
                          name=""
                          id=""
                          @change="changeSelectDisplayType(index, $event)"
                        >
                          <option value="1" selected>混雑状況</option>
                          <option value="2">人数</option>
                        </select>
                      </div>
                      <div class="bar__item bar__item--select" v-else>
                        <select
                          name=""
                          id=""
                          @change="changeSelectDisplayType(index, $event)"
                        >
                          <option value="1">混雑状況</option>
                          <option value="2" selected>人数</option>
                        </select>
                      </div>
                      <input
                        type="number"
                        class="bar__item js-input"
                        :value="data.threshold_1"
                        required="required"
                        @input="data.threshold_1 = Number($event.target.value)"
                      />
                      <input
                        type="number"
                        class="bar__item js-input"
                        :value="data.threshold_2"
                        required="required"
                        @input="data.threshold_2 = Number($event.target.value)"
                      />
                      <input
                        v-if="data.level == 4"
                        type="number"
                        class="bar__item js-input"
                        :value="data.threshold_3"
                        required="required"
                        @input="data.threshold_3 = Number($event.target.value)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </li>
          <li class="content" v-show="isActive === 3" key="3">
            <section class="section">
              <h2 class="section__title">女性用トイレ</h2>
              <div>
                <div class="bar">
                  <div class="bar__text"></div>
                  <div class="bar__main">
                    <span class="bar__item bar__head">段階表示</span
                    ><span class="bar__item bar__head">表示タイプ</span
                    ><span class="bar__item bar__head status--2">混 雑</span
                    ><span class="bar__item bar__head status--3">満 室</span
                    >
                    <!-- <span class="bar__item bar__head status--4">非常に混雑</span> -->
                  </div>
                </div>
                <!-- FIXME filterList(data_list, 1)部分が修正必要です -->
                <div
                  v-for="(data, index) in filterList(data_list, 1)"
                  :key="index"
                  class="data-bar"
                >
                  <div class="bar">
                    <div class="bar__text">
                      <span class="bar__id">{{ data.name }}</span>
                      <span class="bar__name">{{ data.camera_name }}</span>
                    </div>
                    <div class="bar__main">
                      <div class="bar__item bar__item--select" v-if="data.level == 4">
                        <select
                          name=""
                          id=""
                          @change="changeSelectLevel(index, $event)"
                        >
                          <!-- <option value="4" selected>4段階</option> -->
                          <option value="3">3段階</option>
                        </select>
                      </div>
                      <div class="bar__item bar__item--select" v-else>
                        <select
                          name=""
                          id=""
                          @change="changeSelectLevel(index, $event)"
                        >
                          <!-- <option value="4">4段階</option> -->
                          <option value="3" selected>3段階</option>
                        </select>
                      </div>
                      <div
                        class="bar__item bar__item--select"
                        v-if="data.display_type == 1"
                      >
                        <select
                          name=""
                          id=""
                          @change="changeSelectDisplayType(index, $event)"
                        >
                          <option value="1" selected>混雑状況</option>
                          <option value="2">人数</option>
                        </select>
                      </div>
                      <div class="bar__item bar__item--select" v-else>
                        <select
                          name=""
                          id=""
                          @change="changeSelectDisplayType(index, $event)"
                        >
                          <option value="1">混雑状況</option>
                          <option value="2" selected>人数</option>
                        </select>
                      </div>
                      <input
                        type="number"
                        class="bar__item js-input"
                        :value="data.threshold_1"
                        required="required"
                        @input="data.threshold_1 = Number($event.target.value)"
                      />
                      <input
                        type="number"
                        class="bar__item js-input"
                        :value="data.threshold_2"
                        required="required"
                        @input="data.threshold_2 = Number($event.target.value)"
                      />
                      <input
                        v-if="data.level == 4"
                        type="number"
                        class="bar__item js-input"
                        :value="data.threshold_3"
                        required="required"
                        @input="data.threshold_3 = Number($event.target.value)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </li>
          <li class="content" v-show="isActive === 4" key="4">
            <section class="section">
              <h2 class="section__title">馬券発売所</h2>
              <div>
                <div class="bar">
                  <div class="bar__text"></div>
                  <div class="bar__main">
                    <span class="bar__item bar__head">段階表示</span
                    ><span class="bar__item bar__head">表示タイプ</span
                    ><span class="bar__item bar__head status--2">やや混雑</span
                    ><span class="bar__item bar__head status--3">混 雑</span
                    ><span class="bar__item bar__head status--4">非常に混雑</span>
                  </div>
                </div>
                <!-- FIXME filterList(data_list, 1)部分が修正必要です -->
                <div
                  v-for="(data, index) in filterList(data_list, 1)"
                  :key="index"
                  class="data-bar"
                >
                  <div class="bar">
                    <div class="bar__text">
                      <span class="bar__id">{{ data.name }}</span>
                      <span class="bar__name">{{ data.camera_name }}</span>
                    </div>
                    <div class="bar__main">
                      <div class="bar__item bar__item--select" v-if="data.level == 4">
                        <select
                          name=""
                          id=""
                          @change="changeSelectLevel(index, $event)"
                        >
                          <option value="4" selected>4段階</option>
                          <option value="3">3段階</option>
                        </select>
                      </div>
                      <div class="bar__item bar__item--select" v-else>
                        <select
                          name=""
                          id=""
                          @change="changeSelectLevel(index, $event)"
                        >
                          <option value="4">4段階</option>
                          <option value="3" selected>3段階</option>
                        </select>
                      </div>
                      <div
                        class="bar__item bar__item--select"
                        v-if="data.display_type == 1"
                      >
                        <select
                          name=""
                          id=""
                          @change="changeSelectDisplayType(index, $event)"
                        >
                          <option value="1" selected>混雑状況</option>
                          <option value="2">人数</option>
                        </select>
                      </div>
                      <div class="bar__item bar__item--select" v-else>
                        <select
                          name=""
                          id=""
                          @change="changeSelectDisplayType(index, $event)"
                        >
                          <option value="1">混雑状況</option>
                          <option value="2" selected>人数</option>
                        </select>
                      </div>
                      <input
                        type="number"
                        class="bar__item js-input"
                        :value="data.threshold_1"
                        required="required"
                        @input="data.threshold_1 = Number($event.target.value)"
                      />
                      <input
                        type="number"
                        class="bar__item js-input"
                        :value="data.threshold_2"
                        required="required"
                        @input="data.threshold_2 = Number($event.target.value)"
                      />
                      <input
                        v-if="data.level == 4"
                        type="number"
                        class="bar__item js-input"
                        :value="data.threshold_3"
                        required="required"
                        @input="data.threshold_3 = Number($event.target.value)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </li>
        </transition-group>
      </ul>
      <button class="button" @click="btnClick">更新する</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Admin",
  data() {
    return {
      env: process.env.VUE_APP_NODE_ENV,
      isActive: 1,
      data_list: [],
    };
  },
  created() {
    this.fetchGetThresholdsById(this.isActive);
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.handlePopstate);
  },
  mounted() {
    window.addEventListener("popstate", this.handlePopstate);
  },
  methods: {
    // タブ切り替え
    handlePopstate() {
      this.changeTab();
    },
    // タブ切り替え時コンテンツの表示切り替え
    changeTab: function (num) {
      if (num) {
        if (num !== this.isActive) {
          this.fetchGetThresholdsById(num);
          this.isActive = num;
        }
      }
    },
    async fetchGetThresholdsById(id) {
      let base = process.env.VUE_APP_2024_2nd_S3_BASE_URL
      await this.$axios
        .get(
          `${base}${this.env}/thresholds/shop_${id}.json?` +
            new Date().getTime()
        )
        .then((response) => {
          this.data_list = response.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    changeSelectLevel: function (index, event) {
      this.data_list[index]["level"] = Number(event.target.value);
    },
    changeSelectDisplayType: function (index, event) {
      this.data_list[index]["display_type"] = Number(event.target.value);
    },
    Number: function (num) {
      return Number(num);
    },
    filterList: function (list, no) {
      return list.filter((item) => {
        return item.elemental == no;
      });
    },
    btnClick() {
      const inputs = document.querySelectorAll(".js-input");
      let errorFlg = false;
      inputs.forEach((element) => {
        if (element.value === "") {
          element.classList.add("--error");
          element.addEventListener("change", function f() {
            this.removeEventListener("change", f);
            this.classList.remove("--error");
          });
          errorFlg = true;
        }
      });
      if (!errorFlg) {
        const alert = window.confirm("閾値を更新してもよろしいですか？");
        if (alert) {
          let base = process.env.VUE_APP_2024_2nd_API_BASE_URL
          this.$axios
            .post(
              `${base}change-congestion-threshold`,
              {
                target: 'shop',
                id: this.isActive,
                data_list: this.data_list,
              },
              {
                withCredentials: false,
              }
            )
            .then(() => {
              this.$router.go({
                path: this.$router.currentRoute.path,
                force: true,
              });
            })
            .catch((err) => {
              console.error(err);
            });
        }
      }
    },

    // エクスポート（csv出力）
    btnClickExport() {
      const alert = window.confirm("エクスポートを実行してもよろしいでしょうか？");
      if (!alert) { return; }

      // カテゴリ
      const category_list = {1:'飲食店', 2:'ターフィー/UMAJO', 3:'女性用トイレ', 4:'馬券発売所'};
      const category =  category_list[this.isActive];
      // ラベル
      const level_map         = {3:'3段階', 4:'4段階'};
      const display_type_map  = {1:'混雑状況', 2:'人数'};
      // データを生成
      const tmp_list = [
        ['カテゴリ', 'カメラNO', 'カメラ名', '段階表示', '表示タイプ', 'やや混雑', '混雑', '非常に混雑']
      ]
      // レコードを追加
      for(let i in this.data_list){
        let d = this.data_list[i];
        tmp_list.push([
          category,                         // カテゴリ
          d.name,                           // カメラNO
          d.camera_name,                    // カメラ名
          level_map[d.level]                // 段階表示
            ? level_map[d.level]
            : `[${d.level}]`,
          display_type_map[d.display_type]  // 表示タイプ
            ? display_type_map[d.display_type]
            : `[${d.display_type}]`,
          d.threshold_1,                    // やや混雑
          d.threshold_2,                    // 混雑
          d.threshold_3                     // 非常に混雑
        ]);
      }
      // CSV出力用のデータを生成
      let body = '';
      for(let i in tmp_list){
        body += '"' + tmp_list[i].join('","') + '"' + "\n";
      }
      // ファイル生成
      const file_name = `threshold_${this.isActive}_${this.getTimestamp()}`;
      const bom  = new Uint8Array([0xEF, 0xBB, 0xBF]);
      const blob = new Blob([bom, body], {type:"text/csv"});
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = file_name;
      link.click();
    },
    // タイムスタンプを取得
    getTimestamp: function () {
      const date = new Date();
      return date.getFullYear()
        + ('0' + (date.getMonth() + 1)).slice(-2)
        + ('0' + date.getDate()).slice(-2)
        + ('0' + date.getHours()).slice(-2)
        + ('0' + date.getMinutes()).slice(-2)
        + ('0' + date.getSeconds()).slice(-2);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  background: #268300;
  min-width: 1000px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  &__logo {
    margin-right: 40px;
  }
}

.form {
  width: 1000px;
  margin: 20px auto 80px;
}

.section {
  margin-bottom: 60px;
  &__title {
    background: #268300;
    height: 48px;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 24px;
    padding-left: 20px;
    margin-bottom: 20px;
    font-weight: bold;
  }
}

.bar {
  padding: 10px 20px;
  display: flex;

  &__text {
    display: flex;
    align-items: center;
    width: 300px;
    margin-right: 10px;
    font-weight: bold;
  }

  &__main {
    flex: 1;
    display: flex;
    align-items: center;
    select,
    input {
      border: 1px solid #d4d4d4;
      padding-left: 8px;
      background: #fff;
    }

    select {
      appearance: none;
      width: 100%;
      height: 100%;
    }

    input {
      &.--error {
        border: 1px solid #ff0000;
      }
    }
  }

  &__item {
    width: 100px;
    height: 40px;
    &--select {
      position: relative;
      &::after {
        position: absolute;
        content: "";
        right: 9px;
        top: 15px;
        border-bottom: solid 1px #343a40;
        border-right: solid 1px #343a40;
        width: 7px;
        height: 7px;
        transform: rotate(45deg);
      }
    }
    &:not(:last-child) {
      margin-right: 10px;
    }
    &:nth-child(1),
    &:nth-child(2) {
      width: 120px;
    }
  }

  &__head {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d4d4d4;
    font-weight: bold;
  }

  &__id {
    min-width: 85px;
    margin-right: 10px;
  }

  &__name {
    flex: 1;
  }
}

.status {
  &--2 {
    background: #f49600;
    color: #fff;
  }
  &--3 {
    background: #e8104f;
    color: #fff;
  }
  &--4 {
    background: #aa00ff;
    color: #fff;
  }
}

.button {
  display: block;
  text-align: center;
  width: 400px;
  height: 80px;
  background: #268300;
  color: #fff;
  font-size: 24px;
  margin: auto;
  border: 0;
  border-radius: 40px;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
    background: #268300;
  }
  &__wrap {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  &.--medium {
    width: 200px;
    height: 40px;
    font-size: 16px;
    margin: 0;
  }
}

.data-bar {
  &:nth-child(even) {
    background: #efefef;
  }
}

.tabs {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 46px;
  margin: 0 auto 20px;
  max-width: $contentsWidth;

  @media screen and (max-width: $sp_point) {
    justify-content: space-between;
  }

  .tab {
    height: 36px;
    width: 25%;
    border-radius: 4px;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 600;
    color: $c-white;
    padding-bottom: 2px;
    cursor: pointer;
    background-color: #d4d4d4;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:last-child) {
      margin-right: 10px;
    }

    &.active {
      background-color: #6cb901;
      cursor: default;
    }
  }
}

.button__toggle {
  display: inline-block;
  position: relative;
  width: 82px;
  height: 40px;
  border-radius: 50px;
  background-color: #888888;
  box-sizing: content-box;
  cursor: pointer;
  transition: border-color .4s;
  &:has(:checked) {
    background-color: $c-primary;
  }
  &::before {
    position: absolute;
    top: 50%;
    right: 9px;
    transform: translateY(-50%);
    content: 'OFF';
    transition: left 1s;
  }
  &::after {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: $c-white;
    content: '';
    transition: left .4s;
  }
  &:has(:checked)::after {
    left: 44px;
    background-color: $c-white;
    content: '';
  }
  &:has(:checked)::before {
    left: 10px;
    color: $c-white;
    content: 'ON';
  }
  input {
    display: none;
  }
}


</style>
