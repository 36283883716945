<template>
  <div class="content__data" :class="className">
    <div :class="forecast ? 'content__forecast' : 'content__map'">
      <div v-if="shopNameData" class="shop-name">
        <img
          v-lazy="require(`@/assets/img/sp/${shopNameData.image.src}.png`)"
          :srcset="`${require(`@/assets/img/sp/${shopNameData.image.src}@2x.png`)} 2x`"
          :width="shopNameData.image.width"
          :height="shopNameData.image.height"
          :alt="shopNameData.image.alt"
          class="shop-name__image"
        />
      </div>
      <img
        v-if="dataList"
        v-lazy="require(`@/assets/img/sp/${dataList.map.image.src}.png`)"
        :srcset="`${require(`@/assets/img/sp/${dataList.map.image.src}@2x.png`)} 2x`"
        :width="dataList.map.image.width"
        :height="dataList.map.image.height"
        :alt="dataList.map.image.alt"
        class="map__image--map"
      />
      <div v-if="cameraData && cameraData.data" class="camera__multi-wrap">
        <div v-for="(value, key) in cameraData.data" :key="`camera__${key}`">
          <LiveCamera :dataList="value" />
        </div>
      </div>
      <LiveCamera
        v-if="cameraData && !cameraData.data"
        :dataList="cameraData"
      />
      <div
        v-if="statusList"
        class="content__list"
        :class="`${statusList.column} ${statusList.id}`"
      >
        <ul
          v-if="statusList.data"
          class="content__list--inner"
          :class="{ forecast: forecast }"
        >
          <li
            v-for="(item, index) in statusList.data"
            class="content__item"
            :id="(item || {}).id"
            :class="[{ hasId: item.id }, { forecast: forecast }]"
            :key="index"
          >
            <div v-if="forecast" class="content__label-wrap">
              <div v-if="forecast" class="content__label--outline">
                {{ item.label }}
              </div>
              <span v-if="item.supLabel" class="content__supLabel--outline">{{
                item.supLabel
              }}</span>
            </div>
            <img
              v-if="!(item || {}).hasForecast"
              v-lazy="
                require(`@/assets/img/sp/${statusList.path}/${item.src}.png`)
              "
              :srcset="`${require(`@/assets/img/sp/${statusList.path}/${item.src}@2x.png`)}
            2x`"
              :width="item.width"
              :height="item.height"
              :alt="item.alt"
              :class="{ forecast: forecast }"
              class="content__item-image"
            />
            <router-link
              v-else
              :to="`/tokyo/forecast/#${item.hash}`"
              target="_blank"
              rel="noopener"
            >
              <img
                v-lazy="
                  require(`@/assets/img/sp/${statusList.path}/${item.src}.png`)
                "
                :srcset="`${require(`@/assets/img/sp/${statusList.path}/${item.src}@2x.png`)}
            2x`"
                :width="item.width"
                :height="item.height"
                :alt="item.alt"
                :class="{ forecast: forecast }"
                class="content__item-image"
            /></router-link>
            <div
              v-if="!forecast"
              class="iframe-container"
              :class="`iframe-container--${item.src}`"
            >
              <!-- {{ item.info.img }}
              {{ item.info.name }} -->
              <img
                v-if="item.info.img"
                v-lazy="
                  require(`@/assets/img/sp/status/${item.category}/${item.info.img}.png`)
                "
                :srcset="`${require(`@/assets/img/sp/status/${item.category}/${item.info.img}@2x.png`)} 2x`"
                :alt="item.info.alt"
                :class="item.class"
              />
            </div>
            <div
              v-if="item.text"
              class="text-container"
              :class="item.textClass"
            >
              {{ item.text }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import LiveCamera from "../detail/LiveCamera.vue";
export default {
  name: "DataList",
  components: {
    LiveCamera,
  },
  props: {
    className: String,
    column: String,
    forecast: Boolean,
    dataList: {
      type: Object,
      default: null,
    },
    statusList: Object,
    cameraData: Object,
    shopNameData: Object,
  },
};
</script>
