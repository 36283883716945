<template>
  <div>
    <SpHeader :text="text" />
    <LinkHeader
      :left="headerLink.left"
      :right="headerLink.right"
      :show="headerLink.show"
    />
    <div class="toppage-tab-contents-on-site">
      <ul class="tabs" :class="{ fixed: tabBarActive }">
        <li
          @click="changeTab(1)"
          class="tab"
          :class="{ active: isActive === 1 }"
        >
          <img
            v-if="isActive === 1"
            src="@/assets/img/sp/tab/tab-01-white.png"
            srcset="@/assets/img/sp/tab/tab-01-white@2x.png 2x"
            width="40"
            height="14"
            alt="飲食店"
            class="tab__image"
          />
          <img
            v-else
            src="@/assets/img/sp/tab/tab-01.png"
            srcset="@/assets/img/sp/tab/tab-01@2x.png 2x"
            width="40"
            height="14"
            alt="飲食店"
            class="tab__image"
          />
        </li>
        <li
          @click="changeTab(2)"
          class="tab"
          :class="{ active: isActive === 2 }"
        >
          <img
            v-if="isActive === 2"
            src="@/assets/img/sp/tab/tab-02-white.png"
            srcset="@/assets/img/sp/tab/tab-02-white@2x.png 2x"
            width="66"
            height="13"
            alt="フードコート"
            class="tab__image"
          />
          <img
            v-else
            src="@/assets/img/sp/tab/tab-02.png"
            srcset="@/assets/img/sp/tab/tab-02@2x.png 2x"
            width="66"
            height="13"
            alt="フードコート"
            class="tab__image"
          />
        </li>
        <li
          @click="changeTab(4)"
          class="tab"
          :class="{ active: isActive === 4 }"
        >
          <img
            v-if="isActive === 4"
            src="@/assets/img/sp/tab/tab-04-white.png"
            srcset="@/assets/img/sp/tab/tab-04-white@2x.png 2x"
            width="40"
            height="30"
            alt="馬券発売所"
            class="tab__image"
          />
          <img
            v-else
            src="@/assets/img/sp/tab/tab-04.png"
            srcset="@/assets/img/sp/tab/tab-04@2x.png 2x"
            width="40"
            height="30"
            alt="馬券発売所"
            class="tab__image"
          />
        </li>
        <li
          @click="changeTab(3)"
          class="tab"
          :class="{ active: isActive === 3 }"
        >
          <img
            v-if="isActive === 3"
            src="@/assets/img/sp/tab/tab-03-white.png"
            srcset="@/assets/img/sp/tab/tab-03-white@2x.png 2x"
            width="67"
            height="27"
            alt="UMAJO／ターフィーショップ"
            class="tab__image"
          />
          <img
            v-else
            src="@/assets/img/sp/tab/tab-03.png"
            srcset="@/assets/img/sp/tab/tab-03@2x.png 2x"
            width="67"
            height="27"
            alt="UMAJO／ターフィーショップ"
            class="tab__image"
          />
        </li>
        <li
          @click="changeTab(5)"
          class="tab"
          :class="{ active: isActive === 5 }"
        >
          <img
            v-if="isActive === 5"
            src="@/assets/img/sp/tab/tab-05-white.png"
            srcset="@/assets/img/sp/tab/tab-05-white@2x.png 2x"
            width="50"
            height="13"
            alt="パドック"
            class="tab__image"
          />
          <img
            v-else
            src="@/assets/img/sp/tab/tab-05.png"
            srcset="@/assets/img/sp/tab/tab-05@2x.png 2x"
            width="50"
            height="13"
            alt="パドック"
            class="tab__image"
          />
        </li>
      </ul>

      <ul class="contents" :class="{ fixed: tabBarActive }">
        <transition-group :key="renderKey">
          <li class="content" v-show="isActive === 1" key="1">
            <TabFood v-if="isActive === 1" />
          </li>
          <li class="content" v-show="isActive === 2" key="2">
            <TabFoodCourt v-if="isActive === 2" />
          </li>
          <li class="content" v-show="isActive === 3" key="3">
            <TabOther v-if="isActive === 3" />
          </li>
          <li class="content" v-show="isActive === 4" key="4">
            <TabFloor v-if="isActive === 4" />
          </li>
          <li class="content" v-show="isActive === 5" key="5">
            <TabPaddock v-if="isActive === 5" />
          </li>
        </transition-group>
      </ul>
    </div>
    <SpFooter />
    <ModalOutOfTerm :isShow="isModalShow" @from-modal="closeModal" />
  </div>
</template>

<script>
import SpFooter from "../common/Footer.vue";
import SpHeader from "../common/Header.vue";
import TabFloor from "./TabFloor.vue";
import TabFood from "./TabFood.vue";
import TabFoodCourt from "./TabFoodCourt.vue";
import TabOther from "./TabOther.vue";
import TabPaddock from "./TabPaddock.vue";
import LinkHeader from "../../components/detail/LinkHeader.vue";
import ModalOutOfTerm from "../../components/detail/ModalOutOfTerm.vue";

export default {
  components: {
    SpHeader,
    SpFooter,
    TabFloor,
    TabFood,
    TabFoodCourt,
    TabOther,
    TabPaddock,
    LinkHeader,
    ModalOutOfTerm,
  },
  data() {
    return {
      text: "tab",
      isActive: 1,
      tabBarActive: false,
      currentPage: "/",
      currentPath: "/",
      scroll: 0,
      renderKey: 0,
      isModalShow: false,
      headerLink: {
        left: {
          external: false,
          to: "/tokyo/toilet/",
          type: "toilet",
          icon: { name: "woman", width: "13", height: "28" },
          text: {
            title: "女性用トイレの\n混雑状況",
          },
        },
        right: {},
        // アンケート実施日
        survey: {
          external: true,
          to: "/",
          type: "survey",
          icon: { name: "gift", width: "17", height: "17" },
          text: {
            title: "アンケートはこちら",
            sub: "回答するとデジタルギフトが当たる",
          },
        },
        // 駐車場混雑
        parking: {
          external: false,
          to: "/tokyo/parking/",
          type: "parking",
          icon: { name: "car", width: "24", height: "20" },
          text: {
            title: "駐車場の混雑状況",
          },
        },
        show: false,
      },
      envBaseTmp: process.env.VUE_APP_TMP_S3_BASE_URL,
    };
  },
  created() {
    //window変更を検知
    // window.addEventListener("resize", this.setViewport);
    this.checkHeaderLink();
    this.checkParkwins();
  },
  beforeDestroy() {
    // window.removeEventListener("resize", this.setViewport);
    window.removeEventListener("scroll", this.scrollWindow);
    window.removeEventListener("popstate", this.handlePopstate);
  },
  mounted() {
    window.addEventListener("scroll", this.scrollWindow);
    window.addEventListener("popstate", this.handlePopstate);
    this.checkPath();
  },
  methods: {
    setViewport: function () {
      // console.log(this.renderKey);
      this.renderKey = this.renderKey + 1;
      this.$forceUpdate();
    },
    handlePopstate() {
      this.changeTab();
    },
    changeTab: function (num) {
      this.checkHeaderLink();
      this.checkParkwins();
      if (num) {
        if (num !== this.isActive) {
          this.isActive = num;
          switch (this.isActive) {
            case 1:
              this.$router.push("/tokyo/shops/");
              break;
            case 2:
              this.$router.push("/tokyo/food/");
              break;
            case 3:
              this.$router.push("/tokyo/umajo-turfy/");
              break;
            case 4:
              this.$router.push("/tokyo/ticket/");
              break;
            case 5:
              this.$router.push("/tokyo/paddock/");
              break;
          }
        }
      } else {
        this.checkPath();
      }
    },
    async checkHeaderLink() {
      await this.$axios
        .get(`${this.envBaseTmp}header_link.json?` + new Date().getTime())
        .then((response) => {
          // console.log(response);
          const today = new Date();
          const checkSurveyDisplay = (element) => {
            const surveyDay = new Date(element);
            return (
              today.getFullYear() === surveyDay.getFullYear() &&
              today.getMonth() === surveyDay.getMonth() &&
              today.getDate() === surveyDay.getDate()
            );
          };
          if (response.data.some(checkSurveyDisplay)) {
            this.headerLink.right = this.headerLink.survey;
          } else {
            this.headerLink.right = this.headerLink.parking;
          }
          this.headerLink.show = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async checkParkwins() {
      await this.$axios
        .get(`${this.envBaseTmp}parkwins.json?` + new Date().getTime())
        .then((response) => {
          // console.log(response);
          const today = new Date();
          const checkDateMatch = (element) => {
            const checkDay = new Date(element);
            return (
              today.getFullYear() === checkDay.getFullYear() &&
              today.getMonth() === checkDay.getMonth() &&
              today.getDate() === checkDay.getDate()
            );
          };
          if (this.checkSessionStorageAvailable()) {
            // パークウインズ該当日
            if (response.data.some(checkDateMatch)) {
              // sessionStorageに書き込む日付の型：yyyy/MM/dd
              const todayStr =
                today.getFullYear().toString() +
                "/" +
                (today.getMonth() + 1).toString().padStart(2, "0") +
                "/" +
                today.getDate().toString().padStart(2, "0");
              // sessionStorageに日付が残っている場合
              if (sessionStorage.getItem("parkwins")) {
                // 本日がsessionStorageの日付と異なる場合
                if (sessionStorage.getItem("parkwins") !== todayStr) {
                  this.showModal();
                  sessionStorage.setItem("parkwins", todayStr);
                }
              }
              // sessionStorageに日付が無い場合
              else {
                this.showModal();
                sessionStorage.setItem("parkwins", todayStr);
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    scrollWindow() {
      const top = 135;
      this.scroll = window.pageYOffset;
      if (top <= this.scroll) {
        this.tabBarActive = true;
      } else {
        this.tabBarActive = false;
      }
    },
    checkPath() {
      this.currentPage = this.$router.currentRoute.params.page;
      this.currentPath = this.$router.currentRoute.path;
      switch (this.currentPage) {
        case "food":
          this.isActive = 2;
          if (this.currentPath === "/tokyo/food") {
            this.$router.push("/tokyo/food/");
          }
          break;
        case "umajo-turfy":
          this.isActive = 3;
          if (this.currentPath === "/tokyo/umajo-turfy") {
            this.$router.push("/tokyo/umajo-turfy/");
          }
          break;
        case "ticket":
          this.isActive = 4;
          if (this.currentPath === "/tokyo/ticket") {
            this.$router.push("/tokyo/ticket/");
          }
          break;
        case "paddock":
          this.isActive = 5;
          if (this.currentPath === "/tokyo/paddock") {
            this.$router.push("/tokyo/paddock/");
          }
          break;
        default:
          this.isActive = 1;
          break;
      }
    },
    checkSessionStorageAvailable() {
      try {
        sessionStorage.setItem("tabDummy", 1);
        sessionStorage.removeItem("tabDummy");
        return true;
      } catch {
        return false;
      }
    },
    showModal() {
      this.isModalShow = true;
      const $body = document.getElementsByTagName("body")[0];
      $body.classList.add("fixed");
    },
    closeModal() {
      this.isModalShow = false;
      const $body = document.getElementsByTagName("body")[0];
      $body.classList.remove("fixed");
    },
  },
};
</script>
