<template>
  <div>
    <div class="form">
      <div class="form__header">
        <p class="text">対象の駐車場を選択してください</p>
        <ul class="card__group">
          <li
            v-for="(item, index) in statusList"
            :key="index"
            class="card__item"
            :class="[`--card_${item.congestionStatus}`, { clicked: item.cardFlg }]"
            @click="cardSelect(item.parkingId)"
          >
            <div class="card__name">
              {{ item.name }}
            </div>
            <div class="card__status">
              {{ item.label }}
            </div>
          </li>
        </ul>
      </div>
      <div class="form__bottom">
        <div class="form__overlay" v-if="!selectName"></div>
        <p class="text--name">{{ selectName }}</p>
        <div class="text">
          混雑状況を選択して実行するを押してください
        </div>
        <ul class="btn__group">
          <li class="circle --1" @click="btnSelect(1)">
            <div class="button__circle">混雑非表示</div>
          </li>
          <li class="circle --2" @click="btnSelect(2)">
            <div class="button__circle">空車</div>
          </li>
          <li class="circle --3" @click="btnSelect(3)">
            <div class="button__circle">混雑</div>
          </li>
          <li class="circle --4" @click="btnSelect(4)">
            <div class="button__circle">満車</div>
          </li>
        </ul>
        <button class="button" @click="btnClick">実行する</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Admin",
  data() {
    return {
      selectName: null,
      selectStatus: null,
      flg: false,
      env: process.env.VUE_APP_NODE_ENV,
      statusList: [
        {
          "parkingId": 1, // 駐車場id
          "name": "第1駐車場", // 駐車場名
          "label": "混雑非表示", // 混雑状況
          "congestionStatus": 1, // 混雑状況
          "cardFlg": false // 駐車場が選択されているかどうか
        },
        {
          "parkingId": 2,
          "name": "第2駐車場",
          "label": "混雑非表示",
          "congestionStatus": 1,
          "cardFlg": false
        },
        {
          "parkingId": 4,
          "name": "第4駐車場",
          "label": "混雑非表示",
          "congestionStatus": 1,
          "cardFlg": false
        },
      ]
    };
  },
  mounted() {
    for (const i of this.statusList) {
      this.fetchGetCongestionStatus(i.parkingId);
    }
  },
  created() {
    for (const i of this.statusList) {
      this.fetchGetCongestionStatus(i.parkingId);
    }
  },
  methods: {
    // 混雑状況の取得
    async fetchGetCongestionStatus(id) {
      let base = process.env.VUE_APP_2024_2nd_S3_BASE_URL
      await this.$axios
        .get(
          `${base}${this.env}/manual/parking_id_${id}.json?` +
            new Date().getTime()
        )
        .then((response) => {
          const index = this.statusList.find((element) => element.parkingId === id);
          const status = response.data[0].congestion_status
          index.congestionStatus = status;
          this.changeSelectDisplayText(index, status);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    Number: function (num) {
      return Number(num);
    },
    // 混雑状況のテキスト表示
    changeSelectDisplayText: function (index, status) {
      switch (status) {
        case 0: // 調整中
        case 1: // 営業時間外
          index.label = "混雑非表示";
          break;
        case 2:
          index.label = "空車";
          break;
        case 3:
          index.label = "混雑";
          break;
        case 4:
          index.label = "満車";
          break;
      }
    },
    // 混雑状況ボタンの選択
    btnSelect(num) {
      this.selectStatus = num;
      const target = document.querySelector(".on");
      if (target) {
        target.classList.remove("on");
      }
      // 混雑状況ボタンの選択による見た目を変化するためのクラスを付与
      switch (this.selectStatus) {
        case 1:
          document.querySelector(".--1").classList.add("on");
          break;
        case 2:
          document.querySelector(".--2").classList.add("on");
          break;
        case 3:
          document.querySelector(".--3").classList.add("on");
          break;
        case 4:
          document.querySelector(".--4").classList.add("on");
          break;
      }
    },
    // 画面上部のカード（該当駐車場）選択
    cardSelect(parkingId){
      // カード選択時のステータス更新
      for (const i of this.statusList) {
        this.fetchGetCongestionStatus(i.parkingId);
      }
      for (const item of this.statusList) {
        item.cardFlg = false;
      }
      this.parkingId = parkingId;
      const index = this.statusList.find((element) => element.parkingId === parkingId);
      if (index !== undefined && index.cardFlg === false) {
        index.cardFlg = !index.cardFlg;
        this.selectName = index.name;
      }
    },
    // 実行するボタン押下
    btnClick() {
      const inputs = document.querySelectorAll(".js-input");
      let errorFlg = false;
      inputs.forEach((element) => {
        if (element.value === "") {
          element.classList.add("--error");
          element.addEventListener("change", function f() {
            this.removeEventListener("change", f);
            this.classList.remove("--error");
          });
          errorFlg = true;
        }
      });
      if (!errorFlg) {
        const alert = window.confirm("混雑状況を更新してもよろしいですか？");
        if (alert) {
          let base = process.env.VUE_APP_2024_2nd_API_BASE_URL;
          this.$axios
            .post(
              `${base}manual-change-congestion`,
              {
                type: 'parking',
                id: this.parkingId,
                congestion_status: this.selectStatus,
              },
              {
                withCredentials: false,
              }
            )
            .then(() => {
              this.$router.go({
                path: this.$router.currentRoute.path,
                force: true,
              });
            })
            .catch((err) => {
              console.error(err);
            });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  background: #268300;
  min-width: 1000px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  &__logo {
    margin-right: 40px;
  }
}

.form {
  margin: 0 auto;
  &__header {
    padding: 20px 60px 37px;
    background-color: #fdf7e1;
  }
  &__bottom {
    position: relative;
    padding: 20px 40px 0;
    height: 473px;
    max-width: 1200px;
    margin: auto auto 40px;
  }
  &__overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 100;
    opacity: 0.85;
  }
}

.card {
  &__group {
    display: flex;
    gap: 32px;
    justify-content: center;
  }
  &__name {
    font-size: 21px;
    font-weight: bold;
    text-align: center;
    color: #fff;
  }
  &__status {
    width: 206px;
    max-width: 90%;
    height: 76px;
    font-size: 24px;
    font-weight: bold;
    display: grid;
    place-items: center;
    background-color: #fff;
  }
  &__item {
    width: 280px;
    height: 170px;
    border-radius: 10px;
    background-color: #f4962a;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    &.clicked {
      transform: scale(1.15);
      transition: 200ms;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    }
    &.--card_0,
    &.--card_1 {
      background: #d8d8d8;
      .card__status {
        color: #d8d8d8;
      }
      &.clicked {
        background: #fff;
        border: solid 8px #d8d8d8;
        color: #d8d8d8;
        .card__name {
          color: #d8d8d8;
        }
        .card__status {
          color: #fff;
          background: #d8d8d8;
        }
      }
    }
    &.--card_2 {
      background: #4993f7;
      color: #4993f7;
      .card__status {
        color: #4993f7;
      }
      &.clicked {
        background: #fff;
        border: solid 8px #4993f7;
        color: #4993f7;
        .card__name {
          color: #4993f7;
        }
        .card__status {
          color: #fff;
          background: #4993f7;
        }
      }
    }
    &.--card_3 {
      background: #f49600;
      color: #f49600;
      .card__status {
        color: #f49600;
      }
      &.clicked {
        background: #fff;
        border: solid 8px #f49600;
        color: #f49600;
        .card__name {
          color: #f49600;
        }
        .card__status {
          color: #fff;
          background: #f49600;
        }
      }
    }
    &.--card_4 {
      background: #e8104f;
      color: #e8104f;
      .card__status {
        color: #e8104f;
      }
      &.clicked {
        background: #fff;
        border: solid 8px #e8104f;
        color: #e8104f;
        .card__name {
          color: #e8104f;
        }
        .card__status {
          color: #fff;
          background: #e8104f;
        }
      }
    }
  }
}

.btn__group {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 24px;
}
.circle {
  cursor: pointer;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 25%;
  max-width: 188px;
  color: #fff;
  font-size: 32px;
  font-weight: bold;
  &.--0,
  &.--1 {
    background: #d8d8d8;
    &.on {
      background: #fff;
      border: solid 8px #d8d8d8;
      color: #d8d8d8;
    }
  }
  &.--2 {
    background: #4993f7;
    &.on {
      background: #fff;
      border: solid 8px #4993f7;
      color: #4993f7;
    }
  }
  &.--3 {
    background: #f49600;
    &.on {
      background: #fff;
      border: solid 8px #f49600;
      color: #f49600;
    }
  }
  &.--4 {
    background: #e8104f;
    &.on {
      background: #fff;
      border: solid 8px #e8104f;
      color: #e8104f;
    }
  }
}
.circle::before {
  /* 擬似要素で正円を作る */
  display: block;
  content: "";
  padding-top: 100%;
}
.status__label {
  width: 100%;
  padding: 48px 0;
  font-size: 80px;
  font-weight: bold;
  color: white;
  background-color: #d8d8d8;
  text-align: center;
}
.text {
  font-size: 24px;
  line-height: 1.5;
  padding: 0 0 21px;
  text-align: center;
  &--name {
    font-size: 42px;
    font-weight: bold;
    text-align: center;
    height: 63px;
  }
}
.color {
  &--0,
  &--1 {
    background: #d8d8d8;
    animation: none;
  }
  &--2 {
    background: #4993f7;
  }
  &--3 {
    background: #f49600;
  }
  &--4 {
    background: #e8104f;
  }
}
.button__circle {
  width: 100%;
  text-align: center;
}

.button {
  display: block;
  text-align: center;
  width: 560px;
  height: 100px;
  background: #268300;
  color: #fff;
  font-size: 40px;
  margin: auto;
  border: 0;
  border-radius: 50px;
  transition: 0.3s;
  font-weight: bold;
  margin-top: 24px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
    background: #268300;
  }
}

.data-bar {
  &:nth-child(even) {
    background: #efefef;
  }
}
</style>
