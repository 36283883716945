<template>
  <div id="tmp-message" class="content__text-container--tmp">
    <p class="content__text--tmp is-red">
      現在、アクセスが集中し、ページを閲覧しづらい状態になっております。
    </p>
    <p class="content__text--tmp">
      日頃より、東京競馬場をご利用いただきましてありがとうございます。
    </p>
    <p class="content__text--tmp">
      現在、場内の電波状況により、ページ閲覧をしづらい状態になっております。
    </p>
    <p class="content__text--tmp">
      ご迷惑をおかけしまして誠に申し訳ございませんが、しばらく経ってからアクセスしていただきますようお願い申しあげます。
    </p>
  </div>
</template>

<script>
export default {
  name: "TmpMessage",
  props: ["tmp"],
};
</script>
