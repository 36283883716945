<template>
  <div :class="[`border color--${statusNum}`, { timeout: flg }]">
    <div class="form">
      <div class="status_label">{{ statusLabel }}</div>
      <div v-show="flg && statusNum !== 99" class="text--timeout">
        混雑状況を確認し再設定してください
      </div>
      <div v-show="!flg || statusNum == 99" class="text">
        混雑状況を選択して実行するを押してください
      </div>
      <ul class="btn_group">
        <li class="circle --1" @click="btnSelect(1)">
          <div class="button_circle">営業時間外</div>
        </li>
        <li class="circle --2" @click="btnSelect(2)">
          <div class="button_circle">空いて<br />います</div>
        </li>
        <li class="circle --3" @click="btnSelect(3)">
          <div class="button_circle">やや混雑</div>
        </li>
        <li class="circle --4" @click="btnSelect(4)">
          <div class="button_circle">混雑</div>
        </li>
      </ul>
      <button class="button" @click="btnClick">実行する</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Admin",
  data() {
    return {
      timeOut: false,
      statusLabel: "営業時間外",
      statusNum: 99,
      selectStatusNum: null,
      shopId: null,
      timeoutFlg: false,
      lastOperation: {
        type: null, // 最終イベント名
        time: Date.now(), // 最終イベント発生時刻
      },
      eventList: [
        "click", // マウスの左ボタンをクリック
      ],
      intervalMsec: 1000,
      // timeoutMsec: 5 * 1000,
      // FIXME
      timeoutMsec: 1800 * 1000,
      // timeoutMsec: 60 * 1000,
      remainSec: 0,
      flg: false,
      env: process.env.VUE_APP_NODE_ENV,
    };
  },
  mounted() {
    this.fetchGetCongestionStatus();
  },
  created() {
    this.checkPath();
    this.watchEvent();
    this.fetchGetCongestionStatus();
  },
  methods: {
    checkPath() {
      this.currentPath = this.$router.currentRoute.path;
      switch (this.currentPath) {
        case "/tokyo/i/umajo-plus":
        case "/tokyo/i/umajo-plus/":
          this.shopId = 1;
          break;
        case "/tokyo/i/umajo":
        case "/tokyo/i/umajo/":
          this.shopId = 2;
          break;
        case "/tokyo/i/turfy-fuji3":
        case "/tokyo/i/turfy-fuji3/":
          this.shopId = 3;
          break;
        case "/tokyo/i/turfy-fuji2":
        case "/tokyo/i/turfy-fuji2/":
          this.shopId = 4;
          break;
        case "/tokyo/i/turfy-m60":
        case "/tokyo/i/turfy-m60/":
          this.shopId = 5;
          break;
        default:
          this.$router.push("/");
          break;
      }
    },
    async fetchGetCongestionStatus() {
      let base = process.env.VUE_APP_2024_2nd_S3_BASE_URL;
      await this.$axios
        .get(
          `${base}${this.env}/manual/shop_id_${this.shopId}.json?` +
            new Date().getTime()
        )
        .then((response) => {
          this.changeSelectDisplayText(response.data[0].congestion_status);
          console.log(response.data[0].congestion_status);
          this.statusNum = response.data[0].congestion_status;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    Number: function (num) {
      return Number(num);
    },
    changeSelectDisplayText: function (value) {
      switch (value) {
        case 0: // 調整中
        case 1: // 営業時間外
          this.statusLabel = "営業時間外";
          break;
        case 2:
          this.statusLabel = "空いています";
          break;
        case 3:
          this.statusLabel = "やや混雑";
          break;
        case 4:
          this.statusLabel = "混雑";
          break;
      }
    },
    btnSelect(num) {
      this.selectStatusNum = this.Number(num);
      const target = document.querySelector(".on");
      if (target) {
        target.classList.remove("on");
      }
      switch (this.selectStatusNum) {
        case 1:
          document.querySelector(".--1").classList.add("on");
          break;
        case 2:
          document.querySelector(".--2").classList.add("on");
          break;
        case 3:
          document.querySelector(".--3").classList.add("on");
          break;
        case 4:
          document.querySelector(".--4").classList.add("on");
          break;
      }
    },
    updateContent() {
      this.flg = !this.flg;
    },
    watchEvent() {
      //----------------------------------------
      // ユーザー操作が発生したら時間を記録
      //----------------------------------------
      this.eventList.forEach((type) => {
        window.addEventListener(type, () => {
          this.updateLastOperation(type);
        });
      });
      //----------------------------------------
      // 監視する
      //----------------------------------------
      const OPCHECK_TIMER = setInterval(() => {
        const now = Date.now();
        const time = this.lastOperation.time;

        // // タイムアウト判定
        this.timeoutFlg = now - time > this.timeoutMsec;

        // タイムアウト判定結果を表示
        if (this.timeoutFlg) {
          // alert("タイムアウトしました");
          this.updateContent();
          clearInterval(OPCHECK_TIMER);
        }

        // デバッグ表示
        this.remainSec = this.timeoutMsec - (now - time);
        // console.log("[OPCHECK_TIMER] ", this.timeoutFlg, now - time);
      }, this.intervalMsec);
    },
    /**
     * 最終操作時刻を更新する
     *
     * @param {string} type イベント名
     * @param {number} [time=null] イベント発生時刻。省略時は現時刻。
     */
    updateLastOperation(type, time = null) {
      const now = time === null ? Date.now() : time;
      this.lastOperation.type = type;
      this.lastOperation.time = now;

      // console.log("[updateLastOperation] ", type, now);
    },
    btnClick() {
      const inputs = document.querySelectorAll(".js-input");
      let errorFlg = false;
      inputs.forEach((element) => {
        if (element.value === "") {
          element.classList.add("--error");
          element.addEventListener("change", function f() {
            this.removeEventListener("change", f);
            this.classList.remove("--error");
          });
          errorFlg = true;
        }
      });
      if (!errorFlg) {
        const alert = window.confirm("混雑状況を更新してもよろしいですか？");
        if (alert) {
          let base = process.env.VUE_APP_2024_2nd_API_BASE_URL;
          this.$axios
            .post(
              `${base}manual-change-congestion`,
              {
                type: "shop",
                id: this.shopId,
                congestion_status: this.selectStatusNum,
              },
              {
                withCredentials: false,
              }
            )
            .then(() => {
              this.$router.go({
                path: this.$router.currentRoute.path,
                force: true,
              });
            })
            .catch((err) => {
              console.error(err);
            });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  background: #268300;
  min-width: 1000px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  &__logo {
    margin-right: 40px;
  }
}

.form {
  padding: 0 40px;
  margin: 40px auto;
  max-width: 1200px;
}

.btn_group {
  display: flex;
  width: 100%;
  gap: 24px;
}
.circle {
  cursor: pointer;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 25%;
  color: #fff;
  font-size: 32px;
  font-weight: bold;
  &.--1 {
    background: #d8d8d8;
    &.on {
      background: #fff;
      border: solid 8px #d8d8d8;
      color: #d8d8d8;
    }
  }
  &.--2 {
    background: #4993f7;
    &.on {
      background: #fff;
      border: solid 8px #4993f7;
      color: #4993f7;
    }
  }
  &.--3 {
    background: #f49600;
    &.on {
      background: #fff;
      border: solid 8px #f49600;
      color: #f49600;
    }
  }
  &.--4 {
    background: #e8104f;
    &.on {
      background: #fff;
      border: solid 8px #e8104f;
      color: #e8104f;
    }
  }
}
.circle::before {
  /* 擬似要素で正円を作る */
  display: block;
  content: "";
  padding-top: 100%;
}
.status_label {
  width: 100%;
  padding: 48px 0;
  font-size: 80px;
  font-weight: bold;
  color: white;
  background-color: #d8d8d8;
  text-align: center;
}
.text {
  font-size: 24px;
  line-height: 1.5;
  padding: 21px 0;
  text-align: center;
  &--timeout {
    font-size: 40px;
    line-height: 1.5;
    font-weight: bold;
    color: #f00;
    padding: 10px 0;
    text-align: center;
  }
}
.border {
  &.timeout {
    animation: tenmetsu 1s step-end infinite;
    &.color--1 {
      animation: none;
    }
  }
}

@keyframes tenmetsu {
  0% {
  }
  50% {
    border-color: transparent;
  }
  100% {
  }
}
.color {
  &--99,
  &--0,
  &--1 {
    border: solid 60px #d8d8d8;
    animation: none;
    .status_label {
      background: #d8d8d8;
    }
  }
  &--2 {
    border: solid 60px #4993f7;
    .status_label {
      background: #4993f7;
    }
  }
  &--3 {
    border: solid 60px #f49600;
    .status_label {
      background: #f49600;
    }
  }
  &--4 {
    border: solid 60px #e8104f;
    .status_label {
      background: #e8104f;
    }
  }
}
.button_circle {
  width: 100%;
  text-align: center;
}

.button {
  display: block;
  text-align: center;
  width: 560px;
  height: 100px;
  background: #268300;
  color: #fff;
  font-size: 40px;
  margin: auto;
  border: 0;
  border-radius: 50px;
  transition: 0.3s;
  font-weight: bold;
  margin-top: 24px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
    background: #268300;
  }
}

.data-bar {
  &:nth-child(even) {
    background: #efefef;
  }
}
</style>
